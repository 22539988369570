import actions from "./actions";

const initState = {
    resultExist: false,
};

export default function ResultExistReducer(state = initState, action) {
    if (action.type === actions.SET_RESULT_EXIST) {
        return {
            ...state,
            resultExist: action.payload,
        };
    }
    return state;
}