const actions = {
    SET_FILTERS: "SET_FILTERS",

    setFilters: (payload) => ({
        type: actions.SET_FILTERS,
        payload,
    }),
};

export default actions;
