const actions = {
  SET_DESCRIPTION: "SET_DESCRIPTION",

  setDescription: (payload) => ({
    type: actions.SET_DESCRIPTION,
    payload,
  }),
};

export default actions;
