import actions from "./actions";

const initState = {
    motsSecond: true,
};

export default function MotsSecondReducer(state = initState, action) {
    if (action.type === actions.SET_MOTS_SECOND) {
        return {
            ...state,
            motsSecond: action.payload,
        };
    }
    return state;
}