import actions from "./actions";

const initState = {
    isZipcodeUpdated: false,
};

export default function IsZipcodeUpdatedReducer(state = initState, action) {
    if (action.type === actions.SET_IS_ZIPCODE_UPDATED) {
        return {
            ...state,
            isZipcodeUpdated: action.payload,
        };
    }
    return state;
}