const actions = {
  SET_LIST_KEYWORDS: "SET_LIST_KEYWORDS",

  setListKeywords: (payload) => ({
    type: actions.SET_LIST_KEYWORDS,
    payload,
  }),
};

export default actions;
