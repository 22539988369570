import actions from "./actions";

const initState = {
    isZipcodeRemote: false,
};

export default function IsZipcodeRemoteReducer(state = initState, action) {
    if (action.type === actions.SET_IS_ZIPCODE_REMOTE) {
        return {
            ...state,
            isZipcodeRemote: action.payload,
        };
    }
    return state;
}