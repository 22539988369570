const actions = {
    SET_RESULT_EXIST: "SET_RESULT_EXIST",

    setResultExist: (payload) => ({

        type: actions.SET_RESULT_EXIST,
        payload,
    }),
};

export default actions;