import actions from "./actions";

const initState = {
    listConsultant_x: [],
};

export default function Consultant_xListReducer(state = initState, action) {
    if (action.type === actions.SET_LIST_CONSULTANT_X) {
        return {
            ...state,
            listConsultant_x: action.payload,
        };
    }
    return state;
}