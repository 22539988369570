import { createStore } from "redux";
import rootReducer from "./reducer";

function saveToLocalStorage(state) {
    try{
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state", serializedState)

    } catch(e){
        console.log(e)
    }

}

function loadFromLOcalStorage() {
 try{
     const serializedState = localStorage.getItem("state");
     if(serializedState===null) return undefined;
     return JSON.parse(serializedState);

 } catch (e) {
     console.log("line 22",e)
     return undefined
 }
}

const persistedState = loadFromLOcalStorage();


const store = createStore( rootReducer, persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(()=> saveToLocalStorage(store.getState()))

export default store