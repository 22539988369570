import actions from "./actions";

const initState = {
    idJobOrder: 0,
};

export default function IdJobOrderReducer(state = initState, action) {
    if (action.type === actions.SET_ID_JOBORDER) {
        return {
            ...state,
            idJobOrder: action.payload,
        };
    }
    return state;
}