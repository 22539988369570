import actions from "./actions";

const initState = {
  listMatching: [],
};

export default function MatchingListReducer(state = initState, action) {
  if (action.type === actions.SET_LIST_MATCHING) {
    return {
      ...state,
      listMatching: action.payload,
    };
  }
  return state;
}
