import actions from "./actions";

const initState = {
    isZipcodeFound: null,
};

export default function IsZipcodeFoundReducer(state = initState, action) {
    if (action.type === actions.SET_IS_ZIPCODE_FOUND) {
        return {
            ...state,
            isZipcodeFound: action.payload,
        };
    }
    return state;
}