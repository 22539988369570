import actions from "./actions";

const initState = {
    zipcodeList: [],
};

export default function ZipcodeListReducer(state = initState, action) {
    if (action.type === actions.SET_ZIPCODE_LIST) {
        return {
            ...state,
            zipcodeList: action.payload,
        };
    }
    return state;
}
