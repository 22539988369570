const actions = {
    SET_TOTAL_FILTRED_CANDIDATES: "SET_TOTAL_FILTRED_CANDIDATES",

    setTotalFiltredCandidates: (payload) => ({
        type: actions.SET_TOTAL_FILTRED_CANDIDATES,
        payload,
    }),
};

export default actions;
