import actions from "./actions";

const initState = {
    idJobOrderExpress: 0,
};

export default function IdJobOrderExpressReducer(state = initState, action) {
    if (action.type === actions.SET_ID_JOBORDER_EXPRESS) {
        return {
            ...state,
            idJobOrderExpress: action.payload,
        };
    }
    return state;
}