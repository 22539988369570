const actions = {
    SET_SECONDARYSKILLS: "SET_SECONDARYSKILLS",

    setSecondarySkillsSmatchVacancy: (payload) => ({
        type: actions.SET_SECONDARYSKILLS,
        payload,
    }),
};

export default actions;
