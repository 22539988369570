import actions from "./actions";

const initState = {
    listSynonyms: [],
};

export default function SynonymsListReducer(state = initState, action) {
    if (action.type === actions.SET_LIST_SYNONYMS) {
        return {
            ...state,
            listSynonyms: action.payload,
        };
    }
    return state;
}