const actions = {
    SET_ZIPCODE_LIST: "SET_ZIPCODE_LIST",

    setZipcodeList: (payload) => ({
        type: actions.SET_ZIPCODE_LIST,
        payload,
    }),
};

export default actions;
