import actions from "./actions";

const initState = {
    listMatchingExpress: [],
};

export default function MatchingExpressListReducer(state = initState, action) {
    if (action.type === actions.SET_LIST_MATCHING_EXPRESS) {
        return {
            ...state,
            listMatchingExpress: action.payload,
        };
    }
    return state;
}
