import actions from "./actions";

const initState = {
    primarySkillsExpress: [],
};

export default function PrimarySkillsExpressReducer(state = initState, action) {
    if (action.type === actions.SET_PRIMARYSKILLS_EXPRESS) {
        return {
            ...state,
            primarySkillsExpress: action.payload,
        };
    }
    return state;
}
