import actions from "./actions";

const initState = {
    secondarySkills: [],
};

export default function secondarySkillsSmatchVacancyReducer(state = initState, action) {
    if (action.type === actions.SET_SECONDARYSKILLS) {
        return {
            ...state,
            secondarySkills: action.payload,
        };
    }
    return state;
}
