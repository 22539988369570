const actions = {
    SET_COUNTRYCODE: "SET_COUNTRYCODE",

    setCountryCode: (payload) => ({
        type: actions.SET_COUNTRYCODE,
        payload,
    }),
};

export default actions;
