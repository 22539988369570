const actions = {
    SET_PRIMARYSKILLS_EXPRESS: "SET_PRIMARYSKILLS_EXPRESS",

    setPrimarySkillsExpress: (payload) => ({
        type: actions.SET_PRIMARYSKILLS_EXPRESS,
        payload,
    }),
};

export default actions;
