import actions from "./actions";

const initState = {
  description: "",
};

export default function DescriptionReducer(state = initState, action) {
  if (action.type === actions.SET_DESCRIPTION) {
    return {
      ...state,
      description: action.payload,
    };
  }
  return state;
}