import actions from "./actions";

const initState = {
    countryCode: 0,
};

export default function CountryCodeReducer(state = initState, action) {
    if (action.type === actions.SET_COUNTRYCODE) {
        return {
            ...state,
            countryCode: action.payload,
        };
    }
    return state;
}