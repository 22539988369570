import actions from "./actions";

const initState = {
    filters: {
        limit: 10,
        skip: 0,
    },
};

export default function FiltersReducer(state = initState, action) {
    if (action.type === actions.SET_FILTERS) {
        console.log("reduuuuuux", {
            ...state,
            filters: action.payload,
        })
        return {
            ...state,
            filters: action.payload,
        };
    }
    return state;
}