import actions from "./actions";

const initState = {
  listKeywords: [],
};

export default function KeywordsListReducer(state = initState, action) {
  if (action.type === actions.SET_LIST_KEYWORDS) {
    return {
      ...state,
      listKeywords: action.payload,
    };
  }
  return state;
}