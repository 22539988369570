import actions from "./actions";

const initState = {
    categories: {},
};

export default function CategoriesReducer(state = initState, action) {
    if (action.type === actions.SET_CATEGORIES) {
        return {
            ...state,
            categories: action.payload,
        };
    }
    return state;
}