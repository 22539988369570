const actions = {
    SET_PRIMARYSKILLS: "SET_PRIMARYSKILLS",

    setPrimarySkillsSmatchVacancy: (payload) => ({
        type: actions.SET_PRIMARYSKILLS,
        payload,
    }),
};

export default actions;
