const actions = {
  SET_ZIPCODE: "SET_ZIPCODE",

  setZipcode: (payload) => ({
    type: actions.SET_ZIPCODE,
    payload,
  }),
};

export default actions;
