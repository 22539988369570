import { Modal } from "antd";
import React from "react";
import requestData from "../../services/smatchServices";
import {getConnectedUserIfInBH} from "../HomePage/HomePage";
function UserConfirmationModal(props) {
    const BHUser = getConnectedUserIfInBH();
    const {  visible, setVisible, typeCompound, newCompound, id  } = props;
    const existStopWord = typeCompound? typeCompound?.includes("stopword_"): false;

    const   handleOk = async (e) => {
       await requestData("post", `/addNewCompoundKeyWord`, {
            newCompound: newCompound,
            typeCompound: typeCompound,
           id: id,
           BHUser: BHUser
        });
        setVisible(false);
    };
    const handleCancel = (e) => {
        setVisible(false);
    };

    return (
        <div>
            <Modal
                visible={visible}
                title={"Nouveau mot composé détecté !"}
                onOk={handleOk}
                onCancel={handleCancel}
                //cancelButtonProps={{ style: { display: 'none' } }}
                //style={{ top: 20, marginRight: 20, height: 'calc(100vh - 200px)' }}
                width="40%"
            >

                {!existStopWord?<div> Souhaitez-vous que ce mot composé: <strong>{newCompound}</strong> soit pris en consideration lors de la prochaine mise à jour ?</div>:
                 <div> Ce mot composé <strong>{newCompound}</strong> est considéré comme non pertinent, souhaitez vous l’ajouter au dictionnaire smatch lors de la prochaine màj ?</div>}



            </Modal>
        </div>
    );
}

export default UserConfirmationModal;
