const actions = {
    SET_LIST_MATCHING_EXPRESS: "SET_LIST_MATCHING_EXPRESS",

    setMatchingExpress: (payload) => ({
        type: actions.SET_LIST_MATCHING_EXPRESS,
        payload,
    }),
};

export default actions;
