import actions from "./actions";

const initState = {
    totalFiltredCandidates: 0,
};

export default function TotalFiltredCandidatesReducer(state = initState, action) {
    if (action.type === actions.SET_TOTAL_FILTRED_CANDIDATES) {
        return {
            ...state,
            totalFiltredCandidates: action.payload,
        };
    }
    return state;
}