import actions from "./actions";

const initState = {
  title: "",
};

export default function TitleReducer(state = initState, action) {
  if (action.type === actions.SET_TITLE) {
    return {
      ...state,
      title: action.payload,
    };
  }
  return state;
}