import { combineReducers } from "redux";
import MatchingListReducer from "./matching/reducer";
import MatchingExpressListReducer from "./matchingExpress/reducer";
import KeywordsListReducer from "./keywords/reducer";
import SynonymsListReducer from "./synonyms/reducer";
import DescriptionReducer from "./description/reducer";
import ZipcodeReducer from "./zipcode/reducer";
import CountryCodeReducer from "./countryCode/reducer";
import TitleReducer from "./title/reducer";
import TotalReducer from "./total/reducer";
import TotalExpressReducer from "./totalExpress/reducer";
import  IdJobOrderReducer from "./idJobOrder/reducer";
import PrimarySkillsExpressReducer from "./primaryskillsExpress/reducer";
import SecondarySkillsExpressReducer from "./secondaryskillsExpress/reducer";
import AllKeyWordsExpressReducer from "./AllKeywordsExpress/reducer";
import IsSyncExpressReducer from "./is_SyncExpress/reducer";
import IsZipcodeValideReducer from "./isZipCodeValide/reducer";
import IsZipcodeUpdatedReducer from "./isZipcodeUpdated/reducer";
import ZipcodeListReducer from "./autoCompleteZipcodeList/reducer";
import CategoriesReducer from "./categories/reducer";
import IdJobOrderExpressReducer from './idJobOrderExpress/reducer';
import IsZipcodeFoundReducer from "./isZipcodeFound/reducer";
import IsZipcodeRemoteReducer from "./isZipcodeRemote/reducer";
import Consultant_xListReducer from "./consultant_x/reducer";
import FiltersReducer from "./filters/reducer";
import TotalFiltredCandidatesReducer from "./totalFiltredCandidates/reducer";
import MotsPrimReducer from "./motsPrim/reducer";
import MotsSecondReducer from "./motsSecond/reducer";
import ResultExistReducer from "./resultExist/reducer";
import MatchingStartedReducer from "./matchingStarted/reducer";
import NewKeywordsTypeReducer from "./newKeywordsType/reducer";
import MatchingVacanciesListReducer from "./redux-smatchVacancy/matchingSmatchVacancy/reducer";
import primarySkillsSmatchVacancyReducer from "./redux-smatchVacancy/primarySkillsSmatchVacancy/reducer";
import secondarySkillsSmatchVacancyReducer from "./redux-smatchVacancy/secondarySkillsSmatchVacancy/reducer";

export default combineReducers({
  MatchingListReducer,
  MatchingExpressListReducer,
  KeywordsListReducer,
  SynonymsListReducer,
  DescriptionReducer,
  ZipcodeReducer,
  TitleReducer,
  TotalReducer,
  TotalExpressReducer,
  IdJobOrderReducer,
  PrimarySkillsExpressReducer,
  SecondarySkillsExpressReducer,
  AllKeyWordsExpressReducer,
  IsSyncExpressReducer,
  CountryCodeReducer,
  IsZipcodeValideReducer,
  ZipcodeListReducer,
  IsZipcodeUpdatedReducer,
  CategoriesReducer,
  IdJobOrderExpressReducer,
  IsZipcodeFoundReducer,
  IsZipcodeRemoteReducer,
  Consultant_xListReducer,
  FiltersReducer,
  TotalFiltredCandidatesReducer,
  MotsPrimReducer,
  MotsSecondReducer,
  ResultExistReducer,
  MatchingStartedReducer,
  NewKeywordsTypeReducer,
  MatchingVacanciesListReducer,
  primarySkillsSmatchVacancyReducer,
  secondarySkillsSmatchVacancyReducer
});
