import actions from "./actions";

const initState = {
    isZipcodeValide: null,
};

export default function IsZipcodeValideReducer(state = initState, action) {
    if (action.type === actions.SET_IS_ZIPCODE_VALIDE) {
        return {
            ...state,
            isZipcodeValide: action.payload,
        };
    }
    return state;
}