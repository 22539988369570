const actions = {
    SET_TITLE: "SET_TITLE",

    setTitle: (payload) => ({
        type: actions.SET_TITLE,
        payload,
    }),
};

export default actions;
