import React, { useState, useEffect , useRef} from "react";

import {
  Form,
  Layout,
  Input,
  Row,
  Col,
  Button,
  InputNumber,
  Spin,
  Collapse,
  Card,
  Divider,
  Space,
  Modal,
  Tag,
  Table,
  DatePicker,
  message,
  Menu,
  Dropdown,
  AutoComplete,
  Alert,
  Select,
  Tooltip

} from "antd";

import {
  LoadingOutlined,
  SearchOutlined,
  RedoOutlined,
  AliwangwangOutlined,
  FilePdfOutlined,
  CaretDownOutlined,
  FlagOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "antd/dist/antd.css";
import {useDispatch, useSelector, useStore} from "react-redux";
import listKeywordsActions from "../../redux/keywords/actions";
import listSynonymsActions from "../../redux/synonyms/actions";
import listConsultant_xActions from "../../redux/consultant_x/actions";
import descriptionActions from "../../redux/description/actions";
import zipcodeActions from "../../redux/zipcode/actions";
import countryCodeActions from "../../redux/countryCode/actions";
import matchingActions from "../../redux/matching/actions";
import zipcodeListActions from "../../redux/autoCompleteZipcodeList/actions";
import classes from "./HomePage.module.scss";
import requestData from "../../services/smatchServices";
import moment from "moment";
import FormModal from "../Form/FormModal";
import totalActions from "../../redux/total/actions";
import titleActions from "../../redux/title/actions";
import idJobOrderActions from "../../redux/idJobOrder/actions";
import isZipcodeValideActions from "../../redux/isZipCodeValide/actions";
import isZipcodeFoundActions from "../../redux/isZipcodeFound/actions";
import isZipcodeRemoteActions from "../../redux/isZipcodeRemote/actions";
import categoriesActions from "../../redux/categories/actions";
import isZipcodeUpdatedActions from "../../redux/isZipcodeUpdated/actions";
import filtersActions from "../../redux/filters/actions";
import totalFiltredCandidatesActions from "../../redux/totalFiltredCandidates/actions";
import motsPrimActions from "../../redux/motsPrim/actions";
import motsSecondActions from "../../redux/motsSecond/actions";
import  matchingStartedActions from "../../redux/matchingStarted/actions";
import  resultExistActions from "../../redux/resultExist/actions";
import newKeywordsTypeActions from "../../redux/newKeywordsType/actions";
import ResumeModal from "../CandidateResume/ResumeModal";
import UserConfirmationModal from "../UserConfirmation/UserConfirmation";
const { Header, Footer, Content } = Layout;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;



const {setDescription} = descriptionActions;
const { setListKeywords } = listKeywordsActions;
const {setListConsultant_x} = listConsultant_xActions;
const {setListSynonyms} =listSynonymsActions;
const { setZipcode }= zipcodeActions;
const { setCountryCode }= countryCodeActions;
const {setTotal} = totalActions;
const { setMatching }= matchingActions;
const { setTitle }= titleActions;
const {setIdJobOrder}= idJobOrderActions;
const {setIsZipcodeValide}= isZipcodeValideActions;
const {setIsZipcodeFound}= isZipcodeFoundActions;
const {setIsZipcodeRemote}= isZipcodeRemoteActions;
const {setIsZipcodeUpdated} = isZipcodeUpdatedActions;
const {setZipcodeList} = zipcodeListActions;
const {setCategories} = categoriesActions;
const {setFilters} = filtersActions;
const {setTotalFiltredCandidates} = totalFiltredCandidatesActions;
const {setMotsPrim} = motsPrimActions;
const {setMotsSecond} = motsSecondActions;
const {setMatchingStarted}= matchingStartedActions;
const {setResultExist} = resultExistActions;
const { setNewKeywordsType } = newKeywordsTypeActions;


// to delete html balise
const extractContent = (htmlString) => {
  const span = document.createElement('span');
  span.innerHTML = htmlString;
  return span.textContent || span.innerText;
};

 export const getConnectedUserIfInBH = () => {
  try {
    const BhUrl = window.location.href;
     const BhUrlSplit5 = BhUrl.split("UserID=");
     const BhUrlSplit6 = BhUrlSplit5[1].split("&");
    return BhUrlSplit6[0];

  } catch (e) {
    return null
  }
}

export default function HomePage(props) {
  const dispatch = useDispatch();

  const keywordsToDisplay = useSelector(
      (state) => state.KeywordsListReducer.listKeywords
  );

  const synonymsToDisplay = useSelector(
      (state) => state.SynonymsListReducer.listSynonyms
  );

  const consultant_xToDisplay = useSelector(
      (state) => state.Consultant_xListReducer.listConsultant_x
  );

  const descriptionToDisplay = useSelector(
      (state) => state.DescriptionReducer.description
  );

  const titleToDisplay = useSelector(
      (state) => state.TitleReducer.title
  );


  const zipcodeToDisplay = useSelector(
      (state) => state.ZipcodeReducer.zipcode
  );

  const countryCodeToDisplay = useSelector(
      (state) => state.CountryCodeReducer.countryCode
  );

  const totalToDisplay = useSelector(
      (state) => state.TotalReducer.total
  );

  const matchingToDisplay = useSelector(
      (state) => state.MatchingListReducer.listMatching
  );
  const idJobOrderToSend = useSelector(
      (state) => state.IdJobOrderReducer.idJobOrder
  );

  const isZipcodeValide = useSelector(
      (state) => state.IsZipcodeValideReducer.isZipcodeValide
  );

  const isZipcodeFound = useSelector(
      (state) => state.IsZipcodeFoundReducer.isZipcodeFound
  );

  const isZipcodeRemote = useSelector(
      (state) => state.IsZipcodeRemoteReducer.isZipcodeRemote
  );

  const isZipcodeUpdated = useSelector(
      (state) => state.IsZipcodeUpdatedReducer.isZipcodeUpdated
  );

  const zipcodeList = useSelector(
      (state) => state.ZipcodeListReducer.zipcodeList
  );
  const categoriesToDisplay = useSelector(
      (state) => state.CategoriesReducer.categories
  );
  const filters = useSelector(
      (state) => state.FiltersReducer.filters
  );
  const totalFiltredCandidates = useSelector(
      (state) => state.TotalFiltredCandidatesReducer.totalFiltredCandidates
  );

  const motsPrim = useSelector(
      (state) => state.MotsPrimReducer.motsPrim
  );

  const motsSecond = useSelector(
      (state) => state.MotsSecondReducer.motsSecond
  );

  const matchingStarted = useSelector(
      (state) => state.MatchingStartedReducer.matchingStarted
  );

  const resultExist = useSelector(
      (state) => state.ResultExistReducer.resultExist
  );

  const newKeywordsType = useSelector(
      (state) => state.NewKeywordsTypeReducer.newKeywordsType
  );


  const dataIndexRef = useRef();
  const [selectedkeyRef, setSelectedkeyRef] = useState();
  const [tableKey, setTableKey] = useState(0);

  const { status } = props;
  const initialFilters = {
    limit: 10,
    skip: 0,
  };
  let total = null;
 // const [filters, setFilters] = useState(initialFilters);
  const [test, setTest] = useState(0);
  const [TotalPage, setTotalPage] = useState(10);
  const [newWord, setNewWord] = useState([]);
  const [finalWords2, setFinalWords2] = useState([]);
  const [spin, setSpin] = useState(false);
  const [noKeywods, setNoKeywods] = useState(false);
  const [
    showWordsKeysButtonIsActive,
    setShowWordsKeysButtonIsActive,
  ] = useState(true);
  //const [matchingStarted, setMatchingStarted] = useState(false);
  const [selectChoix, setSelectChoix] = useState(true);
  //const [motsPrim, setMotsPrim] = useState(true);
  //const [motsSecond, setMotsSecond] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [keyWords, setKeyWords] = useState("");
  const [SpinnerMotCle, setSpinnerMotCle] = useState(false);
  const [totalPagination, setTotalPagination] = useState(0);
  const [resultat, setResultat] = useState([]);
  const [afficherResultat, setAfficherResultat] = useState(false);
  //const [resultExist, setResultExist] = useState(false);
  const [relancement, setRelancement] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  const [timerTime, setTimerTime] = useState(0);
  const [lastSynonymAdded, setLastSynonymAdded] = useState("");

  const [select, setSelect] = useState(true);
  const [select2, setSelect2] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);

  const [isFilter, setIsFilter] = useState(false);
  const [excludedSkills, setExcludedSkills] = useState(filters.ExcludeSkill);
  const [notes, setNotes] = useState(filters.notes);



  const [seconds, setSeconds] = useState("");
  const [minutes, setMinutes] = useState("");
  const [hours, setHours] = useState("");


  const [visible, setVisible] = useState(false);
  const [visibleResume, setVisibleResume] = useState(false);
  const [resume, setResume] = useState();
  const [resumeName, setResumeName] = useState();
  const [resumeId, setResumeId] = useState();

  const [visibleUserConfirmation, setVisibleUserConfirmation] = useState(false);
  const [typeCompound, setTypeCompound] = useState();
  const [newCompound, setNewCompound] = useState();

  const layout = {
    labelCol: {
      span: 4,
    },
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  /**
   * Check if Smatch is opened in bullhorn
   * @returns {string|null}
   */
  const checkIfInBH = () => {
    try {
      const BhUrl = window.location.href;
      const BhUrlSplit3 = BhUrl.split("EntityID=");
      const BhUrlSplit4 = BhUrlSplit3[1].split("&");
      return BhUrlSplit4[0];

    } catch (e) {
      return null
    }
  }
 const vacancyID= checkIfInBH();
 const BHUser = getConnectedUserIfInBH();

  const deleteTemporaryCollection = () => {
    try{

      const listeMotsPrimaire = [];
      const listeMotsSecondaire = [];
      for (let j = 0; j < keywordsToDisplay.length; j++) {
        if (keywordsToDisplay[j].checked) {
          if (keywordsToDisplay[j].disabledP) listeMotsPrimaire.push(keywordsToDisplay[j].mots);
          if (keywordsToDisplay[j].disabledS) listeMotsSecondaire.push(keywordsToDisplay[j].mots);
        }
      }

      if (listeMotsPrimaire.length !==0 || listeMotsSecondaire.length !==0)
      {
        requestData("delete", `/deleteCollection`, {
          zipcode: zipcodeToDisplay,
          listeMotsPrimaire,
          listeMotsSecondaire});
      }



    }catch (e) {
      console.log("error =>", e);
    }

  }

  const reload = async ()=>{
    deleteTemporaryCollection();
    try{
      window.localStorage.removeItem("state");
      window.location.reload();
    } catch (e) {
      message.error('Please enable cookies for private navigation.');
    }

  }

  useEffect(async () => {

    if (vacancyID) {
      // we are in BH
      try {
        const {description, address, title, categories } = await requestData("get", `/vacancy/${vacancyID}`);
       if(description) dispatch(setDescription(extractContent(description)));
       else Modal.warning({
         title: 'Cette mission est en cours de traitement.',
         content: 'Veuillez la consulter plus tard!',
       });


        await dispatch(setIdJobOrder(vacancyID));
        await dispatch(setCategories(categories));
        address && !isZipcodeUpdated && await dispatch(setZipcode(address.zip)); //
        address && await dispatch(setCountryCode(address.countryCode));
        title &&  await dispatch(setTitle(title));
        //const newTitleToDisplay1 =  store.getState().TitleReducer.title;
         //(zipcodeToDisplay !== 0 && zipcodeToDisplay !== address.zip || title !== newTitleToDisplay1)
        if( vacancyID !==  idJobOrderToSend && parseInt(idJobOrderToSend) ) {
           window.localStorage.removeItem("state");
          window.location.reload();
          dispatch(setListKeywords([]));
          dispatch(setIsZipcodeValide(null));
          dispatch(setResultExist(false));
          dispatch(setTotal(0));
          /// Force to reset ///
          dispatch(setDescription(""));
          dispatch(setListConsultant_x([]));
          dispatch(setListSynonyms([]));
          dispatch(setZipcode(0));
          dispatch(setCountryCode(0));
          dispatch(setMatching([]));
          dispatch(setTitle(""));
          dispatch(setIdJobOrder(0));
          dispatch(setIsZipcodeValide(null));
          dispatch(setIsZipcodeFound(null));
          dispatch(setIsZipcodeRemote(false));
          dispatch(setIsZipcodeUpdated(false));
          dispatch(setZipcodeList([]));
          dispatch(setCategories({}));
          dispatch(setFilters({ limit: 10, skip: 0,}));
          dispatch(setTotalFiltredCandidates(0));
          dispatch(setMotsPrim(true));
          dispatch(setMotsSecond(true));
          dispatch(setMatchingStarted(false));
          dispatch(setResultExist(false));
          dispatch(setNewKeywordsType("description"));

        }

      } catch (e) {
        setIsLoading(false)
      }
    }
    if (matchingToDisplay && matchingToDisplay.length >0) dispatch(setMotsPrim(false));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if( keywordsToDisplay && keywordsToDisplay.length > 0 ){
      setSpin(true);
    }
  }, [keywordsToDisplay]);




  useEffect(() => {
    if( isZipcodeRemote === true ){
      Modal.warning({
        title: 'Filtre distance ne sera pas fonctionnel',
        content: 'Le code postal n\'étant pas renseigné (ou renseigné "remote") au sein de l\'offre, sa valeur sera considérée comme nulle pour cette recherche.',
      });
    }
  }, [isZipcodeRemote]);


  useEffect(() => {
  if( isZipcodeValide === false && isZipcodeFound === true && isZipcodeRemote === false ){
      Modal.error({
        title: 'Code postal erroné',
        content: 'Le code postal intégré est erroné. Merci de sélectionner au sein de la liste déroulante, le code postal souhaité.',
      });
    }
   else if( isZipcodeValide === false && isZipcodeFound === false && isZipcodeRemote === false ){
      Modal.warning({
        title: 'Filtre distance ne sera pas fonctionnel',
        content: 'Le code postal/country code n\'étant pas reconnu par smatch jusqu\'à présent, sa valeur sera donc considérée comme nulle pour cette recherche.',
      });
    }

  }, [isZipcodeValide, isZipcodeFound]);


  useEffect(() => {
    if( matchingToDisplay && matchingToDisplay.length > 0 ){
     dispatch( setResultExist(true));
    }
  }, [matchingToDisplay]);



  const createWordArray = (a) => {
    const variable = a.map((itm) => {
      return {
        mots: itm,
        checked: false,
        disabledS: false,
        disabledP: false,
      };
    });
    dispatch(setListKeywords(variable));
  };


  useEffect(() => {
    const thereIsPrimary= keywordsToDisplay.filter((k)=> k.disabledP === true);
    if(thereIsPrimary.length>0) setSelect(false);
    const thereIsSecond= keywordsToDisplay.filter((k)=> k.disabledS === true);
    if(thereIsSecond.length>0) setSelect2(false);
  }, []);


  const paginate = (page) => {
    setIsLoadingPagination(true);
    const newState = { ...filters };
    newState.skip = (page - 1) * TotalPage;
    newState.searchTrigger = true;
    dispatch(setFilters(newState)) ;
  };

const onValuesChange = async (changedValues) => {
  setNoKeywods(false);
  dispatch(setIsZipcodeValide(null));
  dispatch(setIsZipcodeFound(null));
  dispatch(setIsZipcodeRemote(null));

  if("zipCodeAutoComplete" in changedValues ) {
    dispatch(setZipcode(changedValues.zipCodeAutoComplete));
    dispatch(setIsZipcodeUpdated(true));
  }
}


  const onFinish = async (values) => {
    setSpin(true);
    //setZipcode(values.zipCode);
    const  vacancyID = checkIfInBH();
    let descriptionToBeSent = values.description;

    if (vacancyID) {
      descriptionToBeSent = descriptionToBeSent.concat(". ").concat(titleToDisplay);
    }

   if (values?.zipCode) dispatch(setZipcode(values.zipCode));
   else if (values?.zipCodeAutoComplete){
      dispatch(setZipcode(values.zipCodeAutoComplete));
      dispatch(setIsZipcodeValide(null));
      dispatch(setIsZipcodeFound(null));
      dispatch(setIsZipcodeRemote(null));
    }

    try {
      const { result, synonyms, consultant_x, is_valid, is_found, is_remote,autoComplete_list } = await requestData("post", `/`, {
        desc: descriptionToBeSent,
        zipcode: zipcodeToDisplay,
        countryCode: countryCodeToDisplay
      });

      dispatch(setDescription(values.description));
      createWordArray(result);
      dispatch(setListSynonyms(synonyms));
      dispatch(setListConsultant_x(consultant_x));
      dispatch(setIsZipcodeValide(is_valid));
      dispatch(setIsZipcodeRemote(is_remote));
      dispatch(setIsZipcodeFound(is_found));
      let formatedAutoComplete_list =autoComplete_list.map((zip)=>{
        return {value: zip }
      })
      dispatch(setZipcodeList(formatedAutoComplete_list));
      if (result && result.length ===0 && !is_valid && !is_found &&!is_remote)
      setNoKeywods(true);
    } catch (e) {
      console.log("error =>", e);
    }

  };

  // find all key words related to compound key words
  const handleKeyWordsRelatedToCompoundWords = (keyWordsList,splitCompoundWords,compoundKeyWord) => {
    for (let i = 0; i < splitCompoundWords.length ; i++) {
      for (let j = 0; j < keyWordsList.length ; j++){
        if(splitCompoundWords[i] === keyWordsList[j].mots){
          const compoundKW =  keyWordsList.filter((k)=>k.mots.includes("-") && k.mots.split("-")[0]==="consultant");
          const compoundKWNotSelected = compoundKW.filter((nk)=>compoundKeyWord.mots !== nk.mots && nk.disabledP === true );
          if(!(splitCompoundWords[i] === "consultant" && keyWordsList[j].disabledP === true && compoundKeyWord.checked === true || splitCompoundWords[i] === "consultant" && compoundKWNotSelected.length > 0  )){
            keyWordsList[j].disabledP=compoundKeyWord.disabledP;
            keyWordsList[j].disabledS=compoundKeyWord.disabledS;
            keyWordsList[j].checked=compoundKeyWord.checked;
          }


        }
      }
    }
  }

  const handleDiselectingChildOfConsultantX = (keyWordsList,parent,child) => {
    /** Find parent and update it **/
    let indexOfParent = keyWordsList.map(function(e) { return e.mots; }).indexOf(parent);
    keyWordsList[indexOfParent].checked = child.checked;
    keyWordsList[indexOfParent].disabledP = child.disabledP;
    keyWordsList[indexOfParent].disabledS = child.disabledS;

    /** Find children other the clicked one and update **/
    let childrens = consultant_xToDisplay[parent].filter((item)=> item !== child.mots );
    if(childrens?.length > 0){
      for ( let i = 0; i < childrens?.length; i++){
        /** Prevent updating consultant if there is an other consultant_x clicked **/
        const compoundKW =  keyWordsList.filter((k)=>k.mots.includes("-") && k.mots.split("-")[0]==="consultant");
        const compoundKWNotSelected = compoundKW.filter((nk)=>parent !== nk.mots && nk.disabledP === true );
        if(!(childrens[i] === "consultant" && compoundKWNotSelected.length > 0) ){
          let indexOfChild = keyWordsList.map(function(e) { return e.mots; }).indexOf(childrens[i]);
          keyWordsList[indexOfChild].checked = child.checked;
          keyWordsList[indexOfChild].disabledP = child.disabledP;
          keyWordsList[indexOfChild].disabledS = child.disabledS;
        }
      }
    }
  }



  const warningChoices = () => {
    Modal.warning({
      title: 'Alerte',
      content: 'Jusqu\'à 10 mots-clés peuvent être sélectionnés!',
    });
  };


  // handle primary choices
  const handelChoixPrim = async (index) => {
    const finalWordsloocal3 = keywordsToDisplay;
    // primary key words must be under 10 words
    let primaryWords = finalWordsloocal3.filter((wrd)=> wrd.disabledP === true );
    if(primaryWords && primaryWords.length > 9){
      warningChoices();
    }else{
    finalWordsloocal3[index].disabledP = true;
    finalWordsloocal3[index].disabledS = false;
    finalWordsloocal3[index].checked = !finalWordsloocal3[index].checked;

    // check if there is compound key words
    if(finalWordsloocal3[index].mots.includes("-")){
      const splitCompoundWords = finalWordsloocal3[index].mots.split(/-(.+)/);
      if(splitCompoundWords[0].toLowerCase()==="consultant")handleKeyWordsRelatedToCompoundWords(finalWordsloocal3,splitCompoundWords,finalWordsloocal3[index])
    }

    dispatch(setListKeywords(finalWordsloocal3));
      dispatch(setMotsPrim(false));
    setTest(test+1);}
  };

  // start counting time
    const startTimer = () => {
       setTimerOn(true);
        setTimerTime(0);
      };


  // handel secondary choices
  const handelChoixSecond = (index) => {
    const finalWordsloocal4 = keywordsToDisplay;
    // secondary key words must be under 10 words
    let secondWords = finalWordsloocal4.filter((wrd)=> wrd.disabledS === true );
      if(secondWords && secondWords.length > 9){
        warningChoices();
    }else{
      finalWordsloocal4[index].disabledP = false;
      finalWordsloocal4[index].disabledS = true;
      finalWordsloocal4[index].checked = !finalWordsloocal4[index].checked;

      // check if there is compound key words
      if(finalWordsloocal4[index].mots.includes("-")){
        const splitCompoundWords = finalWordsloocal4[index].mots.split(/-(.+)/);
        if(splitCompoundWords[0].toLowerCase()==="consultant")handleKeyWordsRelatedToCompoundWords(finalWordsloocal4,splitCompoundWords,finalWordsloocal4[index])
      }
      // setFinalWords(finalWordsloocal4);
      dispatch(setListKeywords(finalWordsloocal4));
        dispatch( setMotsSecond(false));
      setTest(test+1);
      finalWordsloocal4[index].disabledS = true;
    }


  };


  useEffect(() => {
    let interval;
    if (timerOn)
      interval = setInterval(
          () => setTimerTime((prevTime) => prevTime + 1000),
          1000
      );
    return () => clearInterval(interval);
  }, [timerOn]);




  const warning = () => {
    message.warning('Prière de sélectionner la liste des mots primaires!');
  };


  const checkType = (data) => {
        if(data && typeof data === "string") return data.split(" ").map((item)=>parseInt(item, 10) );
        else if (data && typeof data === "object") return data.flat(Infinity)[0].split(" ").map((item)=>parseInt(item, 10) );
        else return null;
  }


  const getResult = async () => {
    const fl = [];
    const listeMotsPrimaire = [];
    const listeMotsSecondaire = [];
    if(keywordsToDisplay?.length >0){
      for (let j = 0; j < keywordsToDisplay.length; j++) {
        if (keywordsToDisplay[j].checked) {
          fl.push(keywordsToDisplay[j].mots);
          if (keywordsToDisplay[j].disabledP) listeMotsPrimaire.push(keywordsToDisplay[j].mots);
          if (keywordsToDisplay[j].disabledS)
            listeMotsSecondaire.push(keywordsToDisplay[j].mots);
        }
      }

      try {
        let categoriesToBeSend = [];
        if(categoriesToDisplay?.data?.length > 0 ) categoriesToBeSend = categoriesToDisplay.data.map((cat)=> cat.name);

        const { glob } = await requestData("post", `/matching`, {
          fl,
          fileSize: 0,
          zipcode: zipcodeToDisplay,
          listeMotsPrimaire,
          listeMotsSecondaire,
          limit: filters.limit,
          skip: filters.skip,
          idJobOrder:parseInt(idJobOrderToSend)|| null,
          categories: categoriesToBeSend.toString(),
          BHUser: BHUser,
          filter: {
            id: checkType(filters.id),
            name: filters.name,
            email: filters.email,
            value: parseInt(filters.value, 10)||null ,
            bh_score: parseInt(filters.bh_score, 10)||null ,
            cv_sent: parseInt(filters.cv_sent, 10)||null ,
            status: filters.status,
            countryCode:filters.countryCode,
            zipCode: filters.zipCode,
            distance: parseInt(filters.distance, 10)|| null,
            occupation: filters.occupation,
            primarySkills: filters.primarySkills &&  filters.primarySkills !== "" ? filters.primarySkills?.split(" "): null,
            ExcludeSkill:  filters.ExcludeSkill && filters.ExcludeSkill !== ""? filters.ExcludeSkill?.split(" "): null,
            notes: checkType(filters.notes),
            employmentPreference: filters.employmentPreference,
            dateLastModifiedStart: filters.dateLastModifiedStart,
            dateLastModifiedEnd: filters.dateLastModifiedEnd,
            dateAvailableStart: filters.dateAvailableStart,
            dateAvailableEnd: filters.dateAvailableEnd,
            sortValue: filters.sortValue,
            sortBh_score: filters.sortBh_score,
            sortDistance: filters.sortDistance,
            sortCandidateId : filters.sortCandidateId,
            sortCV_sent : filters.sortCV_sent,


          },
        });

        setFinalWords2(keywordsToDisplay);
        // await sleep(a);

        if(glob[0]==="no candidate"){
          setIsLoadingPagination(false);
          //stop timer
          setTimerOn(false);
          // stop spiner
          dispatch(setMatchingStarted(false));
          dispatch(setMotsPrim(false));
          dispatch(setMotsSecond(false));
          setSelectChoix(true);
          setFinalWords2([]);
        }


        if(glob){
          setIsLoadingPagination(false);
          total = glob.total;
          let res = glob.candidates;
          //stop timer
          setTimerOn(false);
          // stop spiner
          dispatch( setMatchingStarted(false));


          dispatch(setResultExist(true));
          setResultat(res);
          dispatch(setMatching(res));//setResultat2(res);

          if(totalToDisplay !== glob.total && totalToDisplay !==0 ){
            dispatch(setTotalFiltredCandidates(glob.total));
            setIsFilter(true);

          }else{
            dispatch(setTotalFiltredCandidates(0));
          }
          if(totalToDisplay===0){
            dispatch(setTotal(glob.total));
          }
          setTotalPagination(glob.total);


          setRelancement(relancement + 1);
          dispatch(setMotsPrim(false));
          dispatch(setMotsSecond(false));
          setSelectChoix(true);
          setFinalWords2([]);

        }
      } catch (e) {
        console.log("error =>", e);
      }
    }


  }

  useEffect(() => {
    if( !motsPrim || !motsSecond){
      //dispatch(setMatchingStarted(true));// TO VERIFY
      dispatch(setMatching([]))
      setResultat([]);
      setAfficherResultat(false);
      if(resultExist && vacancyID ===  idJobOrderToSend){
        getResult();
      }
    }
  }, [filters]);



  // start matching action
  const applyMatching = async () => {
    if( motsPrim && motsSecond){
      warning();
    }else{
      // setEnable2(false);
      dispatch(setMatchingStarted(true));
      dispatch(setMatching([]))//setResultat2([]);///
      setResultat([]);
      setAfficherResultat(false);
      dispatch( setResultExist(false));

      startTimer();

      await  getResult();
    }


  };


  const searchQuery = (dataIndex, value,confirm) => {
    confirm();
    setIsLoadingPagination(true);
    let FiltersToSend = {...filters};
    FiltersToSend = {...FiltersToSend, [dataIndex]: value,"ExcludeSkill": excludedSkills, skip:0 }
    dispatch(setFilters(FiltersToSend)) ;
    dataIndexRef.current=null;

  };

  const handleTableChange = (pagination, filterst, sorter) => {
    if(sorter && sorter.order ){
      setIsLoadingPagination(true);
      if(sorter.field === "value"){
        if((pagination.current-1)*10 === filters.skip){
          dispatch( setFilters({...filters,  "sortValue": sorter.order,"sortDistance": sorter.order, "sortCV_sent":0, "sortCandidateId": 0,"sortBh_score": 0, skip: 0, limit: pagination.pageSize }));
        }
        else dispatch(setFilters({...filters,  "sortValue": sorter.order,"sortDistance": sorter.order, "sortCV_sent":0, "sortCandidateId": 0,"sortBh_score": 0, limit: pagination.pageSize, skip: ((pagination.current - 1) * pagination.pageSize) }));
      }
      if(sorter.field === "bh_score"){
        if((pagination.current-1)*10 === filters.skip){
          dispatch( setFilters({...filters,  "sortBh_score": sorter.order, "sortCV_sent":0,"sortValue": 0,"sortDistance": 0, "sortCandidateId": 0, skip: 0, limit: pagination.pageSize }));
        }
        else dispatch(setFilters({...filters,  "sortBh_score": sorter.order, "sortCV_sent":0,"sortValue": 0,"sortDistance": 0, "sortCandidateId": 0, limit: pagination.pageSize, skip: ((pagination.current - 1) * pagination.pageSize) }));
      }
      if(sorter.field === "distance"){
        if((pagination.current-1)*10 === filters.skip){
          dispatch(setFilters({...filters, "sortDistance": sorter.order, "sortCV_sent":0, "sortCandidateId": 0, "sortValue": 0, "sortBh_score": 0,skip: 0, limit: pagination.pageSize}));
        }
        else{
          dispatch(setFilters({...filters, "sortDistance": sorter.order, "sortCV_sent":0, "sortCandidateId": 0, "sortValue": 0,"sortBh_score": 0, limit: pagination.pageSize, skip: ((pagination.current - 1) * pagination.pageSize)}));
        }
      }
        if(sorter.field === "id"){
          if((pagination.current-1)*10 === filters.skip)  dispatch(setFilters({ ...filters,  "sortCandidateId": sorter.order , "sortCV_sent":0, "sortValue": 0, "sortDistance": 0, "sortBh_score": 0, skip: 0, limit: pagination.pageSize }));
          else dispatch(setFilters({ ...filters, "sortCandidateId": sorter.order,  "sortCV_sent":0, "sortValue": 0, "sortDistance": 0, "sortBh_score": 0, limit: pagination.pageSize, skip: ((pagination.current - 1) * pagination.pageSize) }));
      }
      if(sorter.field === "cv_sent"){
        if((pagination.current-1)*10 === filters.skip)  dispatch(setFilters({ ...filters, "sortCV_sent":sorter.order, "sortCandidateId": 0, "sortValue": 0, "sortDistance": 0, "sortBh_score": 0, skip: 0, limit: pagination.pageSize }));
        else dispatch(setFilters({ ...filters, "sortCV_sent":sorter.order, "sortCandidateId": 0, "sortValue": 0, "sortDistance": 0, "sortBh_score": 0, limit: pagination.pageSize, skip: ((pagination.current - 1) * pagination.pageSize) }));
      }
    }
   else if (pagination && pagination.pageSize){
      setTotalPage(pagination.pageSize);
      dispatch(setFilters({ ...filters, limit: pagination.pageSize, skip: ((pagination.current - 1) * pagination.pageSize) }));
    }

  };


  const Search = (dataIndex) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, confirm }) => {
      return (
          <div style={{ padding: 8 }}>
            {dataIndex === "dateLastModified" || dataIndex === "dateAvailable" ? (
                <RangePicker
                    initialValues={moment("2020-01-01", "YYYY-MM-DD")}
                    defaultValue={dataIndex === "dateLastModified" && filters.dateLastModifiedStart?[moment(filters.dateLastModifiedStart), moment(filters.dateLastModifiedEnd)]:
                        filters.dateAvailableStart && dataIndex === "dateAvailable"?[moment(filters.dateAvailableStart), moment(filters.dateAvailableEnd)] : null}
                    format="YYYY-MM-DD"
                    onChange={(dates, dateStrings) => {
                      if(dataIndex === "dateAvailable"){
                        if(dateStrings[0] === "" && dateStrings[1] === "" ){
                          confirm();
                          clearFilters();
                          setIsLoadingPagination(true);
                          dispatch(setFilters({
                                ...filters,
                            ["dateAvailableStart"]: initialFilters["dateAvailableStart"],
                            ["dateAvailableEnd"]: initialFilters["dateAvailableEnd"],
                            skip: 0,
                          }));
                          dispatch( setTotalFiltredCandidates(0));
                          setTotalPagination(0);
                          setIsFilter(false);

                        }else
                        {
                          confirm();
                          setIsLoadingPagination(true);
                          dispatch(setFilters({ ...filters, ["dateAvailableStart"]: dateStrings[0],  ["dateAvailableEnd"]: dateStrings[1], skip:0 }));
                        }
                      }
                      if(dataIndex === "dateLastModified"){
                        if(dateStrings[0] === "" && dateStrings[1] === "" ){
                          confirm();
                          clearFilters();
                          setIsLoadingPagination(true);
                          dispatch(setFilters({
                            ...filters,
                            ["dateLastModifiedStart"]: initialFilters["dateLastModifiedStart"],
                            ["dateLastModifiedEnd"]: initialFilters["dateLastModifiedEnd"],
                            skip: 0,
                          }));
                          dispatch(setTotalFiltredCandidates(0));
                          setTotalPagination(0);
                          setIsFilter(false);

                        }else
                        {
                        confirm();
                        setIsLoadingPagination(true);
                          dispatch(setFilters({ ...filters, ["dateLastModifiedStart"]: dateStrings[0],  ["dateLastModifiedEnd"]: dateStrings[1], skip:0 })) ;
                        }
                      }

                    }}
                    style={{ marginBottom: 10, display: "flex" }}
                />
            ) : dataIndex === "distance" ? (
                <Input
                    placeholder={`Search by ${dataIndex}`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value)}
                    onPressEnter={() => searchQuery(dataIndex, selectedKeys, confirm)}
                    style={{ marginBottom: 3, display: "flex" }}
                />
            ): dataIndex === "primarySkills" ? (
                <Input
                    key={dataIndex}
                    placeholder={`Search by ${dataIndex}`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value)}
                    onPressEnter={() => {
                      message.warning('Veuillez cliquer sur le bouton Search!');
                    }}
                    style={{ marginBottom: 10, display: "flex" }}
                />
            ): dataIndex !== "notes" ? (
                <Input
                    key={dataIndex}
                    placeholder={dataIndex === "bh_score" ? `Search by Data Quality Score`:`Search by ${dataIndex}` }
                    //placeholder={`Search by ${dataIndex}` }
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value)}
                    onPressEnter={() => searchQuery(dataIndex, selectedKeys, confirm)}
                    style={{ marginBottom: 10, display: "flex" }}
                />
            ):  null}
           <div>
             <div >
               {dataIndex === "distance" ? (
                   <div style={{color: "red", width: 250 }}>
                     Les candidats pour lesquels la distance est inférieure à la valeur saisie seront affichés.
                   </div>
               ): null}
             </div>
             <div >
             </div>
             <div >
               {dataIndex === "primarySkills" ? (
                   <Input
                       key={"ExcludeSkill"}
                       placeholder={"Exclude skill"}
                       defaultValue={filters.ExcludeSkill && excludedSkills}
                       value={excludedSkills}
                       onChange={(e) =>{
                         setExcludedSkills( e.target.value);
                         //setSelectedkeyRef( e.target.value) ;
                         //dataIndexRef.current ="ExcludeSkill";
                       } }
                       onPressEnter={() => {
                         message.warning('Veuillez cliquer sur le bouton Search!');
                         //searchQuery(null, null, confirm)
                       }}
                       style={{ marginBottom: 10, display: "flex" }}
                   />
               ): null}
             </div>


             <div >
               {dataIndex === "notes" ? (
                   <Input
                       key={"notes"}
                       placeholder={"Exclude notes"}
                       value={selectedkeyRef}
                       defaultValue={filters.notes && notes}
                       onChange={(e) =>{
                         setNotes(e.target.value);
                         setSelectedkeyRef( e.target.value) ;
                         dataIndexRef.current ="notes";
                       } }
                       onPressEnter={() => {
                         searchQuery("notes", selectedkeyRef, confirm)
                       }}
                       style={{ marginBottom: 10, display: "flex" }}
                   />
               ): null}
             </div>
             <div >
               {dataIndex === "notes" ? (
                   <div style={{color: "red", width: 250 }}>
                     Entrez les Vacancy ID (séparés par un espace) pour lesquels vous souhaitez exclure les candidats ayant une note "Sourcing No Go Mission"
                   </div>
               ): null}
             </div>
            {dataIndex !== "dateLastModified" && dataIndex !== "dateAvailable" ? (  <Space>
              <Button
                  type="primary"
                  onClick={() =>{
                    if(excludedSkills ) searchQuery(null, null, confirm)
                    if(dataIndexRef.current === "notes" ) searchQuery(dataIndexRef.current, selectedkeyRef, confirm)
                    else if (dataIndex !== "primarySkills") searchQuery(dataIndex, selectedKeys, confirm)
                    if (dataIndex === "primarySkills" && typeof selectedKeys !== "object"  ){
                      searchQuery(dataIndex, selectedKeys, confirm)
                  }}
                  }
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 80 }}
              >
                Search
              </Button>
              <Button
                  onClick={ () =>{
                    clearFilters();
                    confirm();
                    setIsLoadingPagination(true);
                    /** reset primarySkills and ExcludeSkill**/
                    if(dataIndex==="primarySkills"){
                      setExcludedSkills("");
                      dispatch(setFilters({
                        ...filters,
                        [dataIndex]: initialFilters[dataIndex],
                        ["ExcludeSkill"]:  initialFilters["ExcludeSkill"] ,
                        skip: 0,
                      }));
                    }
                   else if(dataIndex==="notes"){
                     setNotes("");
                      dispatch(setFilters({
                        ...filters,
                        [dataIndex]: initialFilters[dataIndex],
                        ["notes"]:  initialFilters["notes"] ,
                        skip: 0,
                      }));
                    }
                    else dispatch(setFilters({
                        ...filters,
                      [dataIndex]: initialFilters[dataIndex],
                      skip: 0,
                    }));
                    dispatch(setTotalFiltredCandidates(0));
                    setIsFilter(false);
                    setTotalPagination(0);
                    setSelectedkeyRef("") ;
                  }

                  }
                  size="small"
                  style={{ width: 80 }}
              >
                Reset
              </Button>
              <Button
                  onClick={() => {
                    clearFilters();
                    confirm();
                    setIsLoadingPagination(true);
                    dispatch(setFilters(initialFilters));
                    dispatch(setTotalFiltredCandidates(0));
                    setTotalPagination(0);
                    setSelectedkeyRef("") ;
                    setTableKey(tableKey => tableKey + 1);
                    setIsFilter(false);
                    setExcludedSkills("");
                    setNotes("");
                  }
                  }
                  size="small"
                  style={{ width: 80 }}
              >
                Reset All
              </Button>
            </Space> ): null}
          </div>
          </div>
      );
    },
    filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) => text,
  });
  useEffect(() => {
    setSeconds(`0${Math.floor(timerTime / 1000) % 60}`.slice(-2));
    setMinutes(`0${Math.floor(timerTime / 60000) % 60}`.slice(-2));
    setHours(`0${Math.floor(timerTime / 3600000)}`.slice(-2));
    // setHours("00");
  }, [timerTime]);

  const getExistingSynonyms = (result) => {
    let existingSynonym = {};
    for(let i = 0; i < result.length; i++){
      for (const key in synonymsToDisplay) {
        if(synonymsToDisplay[key].includes(result[i])) {
          existingSynonym[key] = result[i];
          result = result.filter((item)=>item !== result[i]);

        }
      }
    }
    return {existingSynonym: existingSynonym, result: result }
  };

  const displayModalConfirmNewCompound = (new_compound, type) => {
  return  Modal.info({
      title: '',
      width: 620,
      content: (
          <div>

            {/*{newKeywordDisplayed.length !== 0?*/}
            {/*    <div> <h3> ● Les mots clés suivants existent déjà dans la liste des IT keywords :</h3>*/}
            {/*      <div>*/}
            {/*        {newKeywordDisplayed.map((w, index) => {*/}
            {/*          return (*/}
            {/*              <Button*/}
            {/*                  danger*/}
            {/*                  key={index}*/}
            {/*                  style={{ margin: "5px" }}*/}
            {/*                  onClick={() => {}}*/}
            {/*              >*/}
            {/*                {w}*/}
            {/*              </Button>*/}
            {/*          );*/}
            {/*        })}*/}

            {/*      </div></div>  :null}*/}


          </div>
      ),
      onOk() {},
    onCancel(){}
    });
  }


  const Clean = async (keyWords) => {
    // let { nbrSelection, finalWords, newWord } = this.state;
    // this.setState({ SpinnerMotCle: true });
    setSpinnerMotCle(true);
    //let nbrSelection = 0;

    if (keyWords !== "") {
      try {
        const { result,newWord, synonyms, consultant_x,is_valid, new_compound, type, stopword  } = await requestData("post", `/addNewKeyword`, {
          desc: keyWords,
          id: vacancyID,
          type: newKeywordsType,
          BHUser: BHUser
        });
        if(stopword !== ""){
          let typeConcat = "stopword_".concat(type);
          setTypeCompound(typeConcat);
          setNewCompound(stopword);
          setVisibleUserConfirmation(true);
          setSpinnerMotCle(false);
          setClicked(false);
        }
        else if(!is_valid){
          Modal.warning({
            title: 'Alerte',
            content: 'Le type saisi et le nouveau mot-clé écrit ne sont pas cohérents!',
          });
          setSpinnerMotCle(false);
          setClicked(false);
        }
        else if(is_valid && new_compound){
          setTypeCompound(type);
          setNewCompound(new_compound);
          setVisibleUserConfirmation(true);
          setSpinnerMotCle(false);
          setClicked(false);
        }

        else if (result.length > 0 || newWord.length >0) {

          if(synonyms){
            let newSynonymList = {...synonymsToDisplay, ...synonyms};
            dispatch(setListSynonyms(newSynonymList));
          }

          if(consultant_x){
            let newConsultant_xList = {...consultant_xToDisplay, ...consultant_x};
            dispatch(setListConsultant_x(newConsultant_xList));
          }

          setSpinnerMotCle(false);
          setClicked(false);
           /** Check if there are any IT-keyword in result that already exist in existingSynonym**/
          let newres= getExistingSynonyms(result);


           let existingSynonym = newres.existingSynonym;
           let newResult = newres.result;
           let newKeywordDisplayed = [];
          for (let i = 0; i < newResult.length; i++) {
            //nbrSelection++;
            const val = { mots: newResult[i], checked: false, disabledS: false, disabledP: false };
            const found = keywordsToDisplay.find(
                (element) => element.mots === newResult[i]
            );
            if (found === undefined){
              keywordsToDisplay.push(val);
              dispatch(setListKeywords(keywordsToDisplay));
            }else{
              newKeywordDisplayed.push(newResult[i]);
            }
          }

          keywordsToDisplay.sort(function (a, b) {
            const nameA = a.mots.toUpperCase(); // ignore upper and lowercase
            const nameB = b.mots.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });




          setNewWord(newWord);
          if (newWord.length !== 0 || newKeywordDisplayed.length !== 0 || Object.keys(existingSynonym).length !== 0  ) {
            Modal.info({
              title: '',
		      width: 620,
              content: (
                  <div>
                    {newWord.length !== 0 ?  <div>  <h3> ● La liste des mots non pris en compte :</h3>
                      <div>
                        {newWord.map((w, index) => {
                          return (
                              <Button
                                  danger
                                  key={index}
                                  style={{ margin: "5px" }}
                                  onClick={() => {}}
                              >
                                {w}
                              </Button>
                          );
                        })}
                        <p>
                          Ces mots clés seront pris en compte lors de la prochaine
                          mise à jour.
                        </p>
                      </div></div>  : null}


                     {newKeywordDisplayed.length !== 0?
                         <div> <h3> ● Les mots clés suivants existent déjà dans la liste des IT keywords :</h3>
                     <div>
                        {newKeywordDisplayed.map((w, index) => {
                          return (
                                   <Button
                                   danger
                                   key={index}
                                   style={{ margin: "5px" }}
                                   onClick={() => {}}
                                 >
                                    {w}
                                 </Button>
                                );
                              })}

                       </div></div>  :null}

                    {Object.keys(existingSynonym).length !== 0 ?  <div> <h3> ● La liste des mots suivants existent déjà comme synonyme  :</h3>
                      <div>
                        {Object.keys(existingSynonym).map((w, index) => {
                          return (
                              <p>
                                <strong>{existingSynonym[w]} </strong> existe dans la liste déroulante de <strong>{w}</strong>.
                              </p>
                          );
                        })}

                      </div></div>  : null}


                    </div>
              ),
              onOk() {},
            });
          }

        }
      } catch (e) {
        console.log("error =>", e);
      }
    }
  };
  const onFinish2 = async (values) => {
    Clean(values.keyWords);
  };

  const onFinishFailed2 = (errorInfo) => {
    //  console.log("Failed2:", errorInfo);
  };

  const handlerClickPrimaryButton = () => {

    let finalWordsLocal = keywordsToDisplay;
    if (select) {
      let unselectedWords = finalWordsLocal.filter((wrd)=> wrd.disabledP === false && wrd.disabledS === false );
      let selectedWordsPrimary = finalWordsLocal.filter((wrd)=> wrd.disabledP === true && wrd.disabledS === false );
      let allPrimary = [...unselectedWords, ...selectedWordsPrimary];
      if(allPrimary && allPrimary.length > 10){
        warningChoices();
      }else{
        for (let i = 0; i < finalWordsLocal.length; i += 1) {
          if (!finalWordsLocal[i].disabledS) {
            finalWordsLocal[i].disabledP = true;
            finalWordsLocal[i].disabledS = false;
            finalWordsLocal[i].checked = true;
            dispatch(setMotsPrim(false)); //motsPrim = false;
          }
        }
      }

    } else {
      for (let i = 0; i < finalWordsLocal.length; i += 1) {
        if (!finalWordsLocal[i].disabledS) {
          finalWordsLocal[i].disabledP = false;
          finalWordsLocal[i].disabledS = false;
          finalWordsLocal[i].checked = false;
          dispatch(setMotsPrim(true)); // motsPrim = true;
        }
      }
    }
    dispatch(setListKeywords(finalWordsLocal));
    setSelect(!select);
    setSelect2(true);
    setTest(test+1);
  };


  const onClickOverviewCV = async (id) => {

    const {  last_cv, candidate_name, id : candidateID } = await requestData("get", `/resume/${id}`);
    if (!last_cv || last_cv ==="Non renseigné") {
      Modal.warning({
        title: 'Aucun cv trouvé ',
        //content: 'Le code postal n\'étant pas renseigné (ou renseigné "remote") au sein de l\'offre, sa valeur sera considérée comme nulle pour cette recherche.',
      });
    }
   else if(last_cv){
      setResume(last_cv);
      setResumeName(candidate_name);
      setResumeId(candidateID);
      setVisibleResume(true);
    }

  }


  const handlerClickSecondaryButton = () => {

    let finalWordslocal2 = keywordsToDisplay;
    dispatch(setMotsSecond(true));
    // setMotsPrim(false);
    if (select2) {
      let unselectedWords = finalWordslocal2.filter((wrd)=> wrd.disabledP === false && wrd.disabledS === false );
      let selectedWordsSecondary = finalWordslocal2.filter((wrd)=> wrd.disabledP === false && wrd.disabledS === true );
      let allSecondary = [...unselectedWords, ...selectedWordsSecondary];
      if(allSecondary && allSecondary.length > 10){
        warningChoices();
      }else{
      for (let i = 0; i < finalWordslocal2.length; i += 1) {
        if (!finalWordslocal2[i].disabledP) {
          finalWordslocal2[i].disabledP = false;
          finalWordslocal2[i].disabledS = true;
          finalWordslocal2[i].checked = true;
        } else {
          dispatch(setMotsSecond(false)); //motsPrim = false;
        }
      }}
    } else {
      for (let i = 0; i < finalWordslocal2.length; i += 1) {
        if (!finalWordslocal2[i].disabledP) {
          finalWordslocal2[i].disabledP = false;
          finalWordslocal2[i].disabledS = false;
          finalWordslocal2[i].checked = false;
        } else {
          dispatch( setMotsSecond(true)); // motsPrim = false;
        }
      }
    }
    dispatch(setListKeywords(finalWordslocal2));// setFinalWords(finalWordslocal2);
    setSelect2(!select2);
    setSelect(true);
    setTest(test+1);
  };
  const toDate =(a) =>{
    return Date.parse(a);
  }

  const columns = [
    {
      title: "CandidateID",
      dataIndex: "id",
      key: "id",
      sorter: true,
      ...Search("id"),
      filteredValue: filters.id &&[filters.id] ,
      fixed: "left",
      width: "15%",
      render: (_, entity) => {
        return (
            <div>
              <a
                  href={
                    "https://cls22.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=" +
                    entity["candidate"].id
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{marginRight: "20%"}}
              >
                { entity["candidate"].id}
              </a>

              {entity["candidate"]?.cv_type !== " CV Mindquest" ?
                  <Tooltip
                  title="Dernier CV "
                  color="#1890ff"
                  key={entity["candidate"].id}
              >
                <FilePdfOutlined
                    style={{ "color": "#1890ff", fontSize: "18px", marginRight: "20%"}}
                    onClick={() => onClickOverviewCV(entity["candidate"].id)}
                />
              </Tooltip>:
                  <Tooltip
                      title="Dernier CV Mindquest"
                      color="#1890ff"
                      key={entity["candidate"].id}
                  >
                    <img
                        style={{ verticalAlign: "middle", height: "30px",  marginRight: "15%" }}
                        src="../../mq.png"
                        onClick={() => onClickOverviewCV(entity["candidate"].id)}
                    />
                  </Tooltip> }


              <Tooltip    title={entity["candidate"].cv_freshness === 1?"Le dernier CV du candidat a moins de 6 mois":
                                entity["candidate"].cv_freshness === 2 ?"Le dernier CV du candidat a entre 6 mois et 1 an":
                                     "Le dernier CV du candidat a plus d’1 an"} 
                           color={entity["candidate"].cv_freshness === 1?"#32CD32":
                               entity["candidate"].cv_freshness === 2?"#FF8C00":"#FF0000"}
                          key={entity["candidate"].id}    overlayStyle={{maxWidth: '500px'}}>
                <FlagOutlined
                    style={{      "color": entity["candidate"].cv_freshness === 1?"#32CD32":
                          entity["candidate"].cv_freshness === 2?"#FF8C00":"#FF0000"
                      , fontSize: "18px"}} />
              </Tooltip>
 

            </div>
        );
      },
    },
    {
      title: "Candidate’s References",
      dataIndex: "sentiment_analysis",
      key: "sentiment_analysis",
      width: "10%",
      render: (_, entity) => {
        return (
            <div> {entity["candidate"]?.sentiment_analysis === 1 ?
                <Tooltip
                    title={`Ce candidat a ${entity["candidate"]?.positive} référence(s) exclusivement positive(s)`}
                    color="#1890ff"
                    key={entity["candidate"].id}
                    overlayStyle={{maxWidth: '500px'}}
                >
                  <img
                      style={{ verticalAlign: "middle", height: "22px",  marginLeft: "25%"  }}
                      src="../../happy3.png"
                  />
                </Tooltip>
                :entity["candidate"]?.sentiment_analysis === -1 ?
                    <Tooltip
                    title={`Ce candidat a ${entity["candidate"]?.negative} référence(s) exclusivement négative(s)`}
                    color="#1890ff"
                    key={entity["candidate"].id}
                    overlayStyle={{maxWidth: '500px'}}
                >
                  <img
                      style={{ verticalAlign: "middle", height: "22px",  marginLeft: "25%" }}
                      src="../../sad4.png"
                  />
                </Tooltip>:
                    entity["candidate"]?.sentiment_analysis === 0 ?
                        <Tooltip
                        title={`Ce candidat a ${entity["candidate"]?.positive} référence(s) positive(s) et ${entity["candidate"]?.negative} négative(s)`}
                        color="#1890ff"
                        key={entity["candidate"].id}
                        overlayStyle={{maxWidth: '500px'}}
                    >
                      <img
                          style={{ verticalAlign: "middle", height: "22px",  marginLeft: "25%"  }}
                          src="../../confused-face.png"
                      />
                    </Tooltip>: <div>Aucune référence</div> }</div>
        );
      },
    },
    {
      title: "Candidate name",
      dataIndex: "name",
      key: "name",
      ...Search("name"),
      width: "10%",
      filteredValue: filters.name && [filters.name] ,
      render: (_, entity) => {
        return (
            <div>{entity["candidate"].name}</div>
        );
      },
    },
    {
      title:() => (
          <>
                <Tooltip
                    title="La qualité du profil est évaluée sur la qualité des champs adresse, email, job title, téléphone, expérience, IT Career Start Year ainsi
                    que sur la récence de la date available, la présence ou non d’un CV dans les files et le nombre de fois où la personne a été placée ou CV Sent."
                    color="#1890ff"
                    overlayStyle={{maxWidth: '600px'}}
                >
                  <InfoCircleOutlined   style={{ "color": "#1890ff", fontSize: "18px", marginRight: "7%"}} />
                </Tooltip>

            <span>Data Quality</span>
          </>
      ) ,
      dataIndex: "bh_score",
      key: "bh_score",
      sorter: true,
      ...Search("bh_score"),
      filteredValue: filters.bh_score && [filters.bh_score],
      width: "15%",
      render: (bh_score) => {

        return(<div style={{marginLeft: "40%"}}>
          {bh_score}%
        </div>)
      }
    },
    {
      title: "CV sent",
      dataIndex: "cv_sent",
      key: "cv_sent",
      sorter: true,
      ...Search("cv_sent"),
      filteredValue: filters.cv_sent && [filters.cv_sent],
      width: "8%",
      render: (_,entity) => {

        return(<div>
          {entity["candidate"]?.cv_sent}
        </div>)
      },
    },
    {
      title: "Contact",
      dataIndex: "email",
      key: "email",
      ...Search("email"),
      filteredValue: filters.email && [filters.email],
      width: "20%",
      render: (text, record) => {
        return (
            <div>
              <div>{record["candidate"].email && "Email: " + record["candidate"].email}</div>
              <div>
                {record["candidate"].phone &&
                record["candidate"].email !== "None" &&
                "phone: " + record["candidate"].phone}
              </div>
            </div>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "value",
      key: "value",
      sorter: true,
      ...Search("value"),
      filteredValue: filters.value && [filters.value],
      width: "8%",
      render: (value) => {
        const newValue = value.toFixed(2);
        return newValue;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...Search("status"),
      filteredValue: filters.status && [filters.status] ,
      width: "8%",
      render: (_, entity) => {
        return (
            <div>{entity["candidate"].status}</div>
        );
      },
    },
    {
      title: "Country code",
      dataIndex: "countryCode",
      key: "countryCode",
      ...Search("countryCode"),
      filteredValue: filters.countryCode && [filters.countryCode] ,
      width: "8%",
      render: (_, entity) => {
        return (
            <div>{entity["candidate"].address.countryCode}</div>
        );
      },
    },
    {
      title: "Zip code",
      dataIndex: "zipCode",
      key: "zipCode",
      ...Search("zipCode"),
      filteredValue: filters.zipCode && [filters.zipCode],
      width: "8%",
      render: (_, entity) => {
        return (
            <div>{entity["candidate"].address.zip}</div>
        );
      },
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      ...Search("distance"),
      filteredValue: filters.distance && [filters.distance],
      sorter: true,
      width: "8%",
      render: (distance, entity) => {
       if(distance === 50000) return "N/A";
       else  return distance + " km";

      },
    },
    {
      title: "Occupation",
      dataIndex: "occupation",
      sorter: false,
      key: "occupation",
      ...Search("occupation"),
      filteredValue: filters.occupation && [filters.occupation],
      width: "15%",
      render: (_, entity) => {
        return (
            <div>{entity["candidate"].occupation}</div>
        );
      },
    },

    {
      title: "Skills",
      dataIndex: "primarySkills",
      key: "primarySkills",
      ...Search("primarySkills"),
      filteredValue: filters.primarySkills || filters.ExcludeSkill && [filters.primarySkills],
      width: "10%",
      render: (_, entity) => {
        return (
            <div>
              {entity["candidate"].primarySkills.map((tag) => (
                  <Tag color="blue" key={tag}>
                    {tag}
                  </Tag>
              ))}
            </div>
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      ...Search("notes"),
      filteredValue: filters.notes && [filters.notes],
      width: "25%",
      render: (_, entity) => {
        return (
            <div>
              {entity["candidate"].notes.map((tag) =>{
                if (tag.action === "No note with the action: Sourcing No Go Mission was added during the last 12 months")
                  return   (
                      <Tag color="blue" key={tag}>
                        No note with the action: Sourcing No Go
                       <br/>
                       Mission was added during the last 12 months
                        <br/>

                      </Tag>)
                  else
                return   (
                    <Tag color="blue" key={tag}>
                      {tag.jobOrders.map((item)=>item + " ")}  -    {tag.action}  -    {tag.dateAdded}
                    </Tag>
                )
              } )}
            </div>
        );
      },
    },
    {
      title: "Employment preference",
      dataIndex: "employmentPreference",
      sorter: false,
      key: "employmentPreference",
      ...Search("employmentPreference"),
      filteredValue: filters.employmentPreference && [filters.employmentPreference],
      width: "11%",
      render: (_, entity) => {
        return (
            <div>
              {entity["candidate"].employmentPreference.map((emp) => (
                  <div style={{marginLeft: 3}}>
                    {emp}
                  </div>
              ))}
            </div>
        );
      },
    },
    {
      title: "Date last modified",
      dataIndex: "dateLastModified",
      key: "dateLastModified",
      width: "8%",
      filteredValue: filters.dateLastModifiedStart && filters.dateLastModifiedEnd? [moment(filters.dateLastModifiedStart), moment(filters.dateLastModifiedEnd)] : null,
      ...Search("dateLastModified"),
      render: (_, entity) => {
        return (
            <div>{entity["candidate"].dateLastModified}</div>
        );
      },
    },
    {
      title: "Date available",
      dataIndex: "dateAvailable",
      key: "dateAvailable",
      // sorter: (a, b) => toDate(a["candidate"].dateAvailable) - toDate(b["candidate"].dateAvailable),
      width: "9%",
      filteredValue: filters.dateAvailableStart && filters.dateAvailableEnd? [moment(filters.dateAvailableStart), moment(filters.dateAvailableEnd)] : null,
      ...Search("dateAvailable"),
      render: (_, entity) => {
        return (
            <div>{entity["candidate"].dateAvailable}</div>
        );
      },
    },
  ];


  const handleSelectChange = (value) => {
    dispatch(setNewKeywordsType(value));

  };

  const getForm =()=> {
    setVisible(true);
  }


  const getKeyByValue=( value)=> {
    let parentWord = Object.keys(synonymsToDisplay).find(key =>  synonymsToDisplay[key].includes(value) );
    return keywordsToDisplay.find(word=> word.mots === parentWord);
  }


  const addSynonymToKeyWords = ({ key }) => {
    let val = { mots: key, checked: false };
    const foundSyno = keywordsToDisplay.find(
        (element) => element.mots === key
    );
    if (foundSyno === undefined){
      let parent = getKeyByValue(key);
      val = { ...val, checked: parent?.checked, disabledP: parent?.disabledP, disabledS: parent?.disabledS   };
      keywordsToDisplay.push(val);
      dispatch(setListKeywords(keywordsToDisplay));
      // set a state to force the component to rerender
      setLastSynonymAdded(key);
    }

  };

  const menu =(keyWord) =>{
    let keyWordSynonyms = synonymsToDisplay[keyWord];
    return(
        <Menu onClick={addSynonymToKeyWords}>
          {keyWordSynonyms?.length > 0
              ? keyWordSynonyms.map((syno) => {
                return (
                    <Menu.Item key={syno}>
                      {syno}
                    </Menu.Item>
                );
              })
              : null}
        </Menu>
    );
  }

  return  (
        <div>
          {vacancyID?
            <div>
              <FormModal
                  visible={visible}
                  setVisible={setVisible}
              />
              <ResumeModal
                  visible={visibleResume}
                  setVisible={setVisibleResume}
                  resume={resume}
                  name={resumeName}
                  id={resumeId}
              />

              <UserConfirmationModal
                  visible={visibleUserConfirmation}
                  setVisible={setVisibleUserConfirmation}
                  typeCompound={typeCompound}
                  newCompound={newCompound}
                  id={vacancyID}
              />
              <div className={classes.HomePage}>
                <Layout>

                  <Header>
                    <div className={classes.logo}>
                      <input
                          type="image"
                          src="/logo192.png"
                          width="48"
                          height="48"
                          alt=""
                      />
                    </div>
                  </Header>
                  <div className={classes.centre}>

                    <Content style={{ padding: "70px 150px 0px 150px"  }}>

                      <div className={classes.siteLayout}>
                        <RedoOutlined className={classes.icon}   onClick={reload}/>
                        <div>
                          <Row>
                            { isLoading ? <LoadingOutlined style={{ fontSize: 20,   marginLeft: "50%" }} spin /> : (
                                <Form
                                    {...layout}
                                    layout="vertical"
                                    name="basic"
                                    initialValues={{
                                      remember: true,
                                      description: descriptionToDisplay,
                                      zipCode: zipcodeToDisplay,
                                    }}
                                    style={{ width: "100%" }}
                                    onFinish={onFinish}
                                    onValuesChange={onValuesChange}
                                >
                                  <Form.Item
                                      label="Description"
                                      name="description"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please describe your opportunity!",
                                        },
                                      ]}
                                  >
                                    <TextArea rows={10} placeholder="Description" />

                                  </Form.Item>

                                  {  zipcodeList && zipcodeList.length > 0 || isZipcodeUpdated=== true?( <Form.Item
                                          label="Zip code"
                                          name="zipCodeAutoComplete"

                                      >
                                        <AutoComplete
                                            style={{ width: 200 }}
                                            options={zipcodeList}
                                            defaultValue={zipcodeToDisplay && zipcodeToDisplay}
                                            placeholder="veuillez choisir un zipcode"
                                            filterOption={(inputValue, option) =>
                                                {const inputValueStr = typeof inputValue === 'string' ? inputValue : String(inputValue);
                                                 return option?.value?.toUpperCase().indexOf(inputValueStr.toUpperCase()) !== -1 }
                                            }
                                        />
                                      </Form.Item>)
                                     :
                                      ( <Form.Item
                                          label="Zip code"
                                          name="zipCode"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please input the zip code!",
                                            },
                                          ]}
                                      >

                                        <InputNumber   defaultValue={zipcodeToDisplay && zipcodeToDisplay}  />
                                      </Form.Item> ) }



                                  <Form.Item>
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        disabled={!showWordsKeysButtonIsActive || matchingStarted || resultExist || isZipcodeValide === false && isZipcodeFound === false}
                                    >

                                      Afficher les mots clés
                                    </Button>
                                  </Form.Item>

                                </Form>)}
                          </Row>
                        </div>

                        {(isZipcodeValide === true && isZipcodeFound === true || isZipcodeValide === false && isZipcodeFound === false) && ( <div>
                          {spin && (
                              <div>
                                {!keywordsToDisplay.length && !newWord.length && !noKeywods ? (
                                        <Spin indicator={antIcon} />
                                    ) : noKeywods ? (
                                        <label htmlFor="exampleFormControlFile1">
                                          Aucun mot clé trouvé !
                                        </label>
                                    ):
                                    (
                                        <div>
                                          <label htmlFor="exampleFormControlFile1">
                                            Cliquez sur la case "Mots primaires" ou "Mots secondaires"
                                            et sélectionnez les mots pertinents pour le matching dont
                                            :
                                          </label>

                                          <label htmlFor="exampleFormControlFile1">
                                            - Au mois un mot primaire (le mot ou un synonyme doivent
                                            être prèsents dans le CV)
                                          </label>
                                          <br />
                                          <br />
                                          <Collapse
                                              defaultActiveKey={["1"]}
                                              style={{ textAlign: "center" }}
                                          >
                                            <Panel header="keys words list" key="1">
                                              {keywordsToDisplay.map((w, index) => {
                                                if (!(w.disabledS || w.disabledP))
                                                  return (

                                                      <Dropdown.Button
                                                          key={index}
                                                          icon={w.mots in synonymsToDisplay?<CaretDownOutlined />:null}
                                                          overlay={menu(w.mots)}
                                                          disabled={matchingStarted || resultExist}
                                                          style={{ margin: "10px" }}
                                                          className="myButton "
                                                          onClick={async () => {
                                                            if (selectChoix) await handelChoixPrim(index);
                                                            else handelChoixSecond(index);
                                                          }}
                                                      >
                                                        {w.mots}
                                                      </Dropdown.Button>

                                                  );
                                                return null;
                                              })}
                                            </Panel>
                                          </Collapse>
                                          <Divider />
                                          <Row justify="space-around">
                                            <Col span={10}>
                                              <Space direction="vertical">
                                                <Button
                                                    disabled={matchingStarted || resultExist}
                                                    type="primary"
                                                    size="large"
                                                    style={{ fontSize: "12px", textAlign: "center" }}
                                                    onClick={handlerClickPrimaryButton}
                                                    block
                                                >
                                                  Selectionner/Deselectionner tous les mots
                                                </Button>
                                                <Card
                                                    title="Mots primaires :"
                                                    // style={{ width: "100%" }}
                                                    //className={selectChoix ? "paperActive" : "paper"}
                                                    bodyStyle={
                                                      selectChoix
                                                          ? { background: "#E8E8E8" }
                                                          : { background: "#FFFFFF" }
                                                    }
                                                    onClick={() => {
                                                      setSelectChoix(true);
                                                    }}
                                                >
                                                  {keywordsToDisplay.map((w, index) => {
                                                    if (w.disabledP)
                                                      return (
                                                          <Dropdown.Button
                                                              key={index}
                                                              icon={w.mots in synonymsToDisplay?<CaretDownOutlined />:null}
                                                              overlay={menu(w.mots)}
                                                              disabled={matchingStarted || resultExist}
                                                              style={{ margin: "5px" }}
                                                              onClick={() => {
                                                                let finalWordsloocal5 = keywordsToDisplay;
                                                                /** Check if the clicked word is "consultant" and there is more than one Consultant_x in primary keywords  **/
                                                                const compoundKW =  finalWordsloocal5.filter((k)=>k.mots.includes("-") && k.mots.split("-")[0]==="consultant");
                                                                const compoundKWNotSelected = compoundKW.filter((nk)=>finalWordsloocal5[index].mots !== nk.mots && nk.disabledP === true );
                                                                if(!(finalWordsloocal5[index].mots === "consultant" && compoundKWNotSelected.length >1 )){
                                                                  finalWordsloocal5[
                                                                      index
                                                                      ].disabledP = false;
                                                                  finalWordsloocal5[
                                                                      index
                                                                      ].disabledS = false;
                                                                  finalWordsloocal5[
                                                                      index
                                                                      ].checked = !finalWordsloocal5[index]
                                                                      .checked;
                                                                  let exist = true;
                                                                  let i = 0;
                                                                  while (
                                                                      i < finalWordsloocal5.length &&
                                                                      exist
                                                                      ) {
                                                                    if (finalWordsloocal5[i].disabledP)
                                                                      exist = false;
                                                                    i += 1;
                                                                  }

                                                                  let consultanat_x_exist = Object.keys(consultant_xToDisplay).find(key => {
                                                                   let parentObject = finalWordsloocal5.filter((item)=>item.mots ===key);
                                                                    return consultant_xToDisplay[key].includes(finalWordsloocal5[index].mots) && parentObject[0]?.disabledP });
                                                                  if(consultanat_x_exist){
                                                                    handleDiselectingChildOfConsultantX(finalWordsloocal5,consultanat_x_exist,finalWordsloocal5[index]);
                                                                  }
                                                                  // check if there is compound key words
                                                                  if(finalWordsloocal5[index].mots.includes("-") && !consultanat_x_exist){
                                                                    const splitCompoundWords = finalWordsloocal5[index].mots.split(/-(.+)/);
                                                                    if(splitCompoundWords[0].toLowerCase()==="consultant")handleKeyWordsRelatedToCompoundWords(finalWordsloocal5,splitCompoundWords,finalWordsloocal5[index])
                                                                  }

                                                                  dispatch(setListKeywords(finalWordsloocal5));
                                                                  dispatch( setMotsPrim(exist));
                                                                  dispatch(setMotsSecond(exist));
                                                                  setTest(test+1);
                                                                }

                                                              }}
                                                          >
                                                            {w.mots}
                                                          </Dropdown.Button>
                                                      );
                                                    return null;
                                                  })}
                                                </Card>
                                                <p style={{ textAlign: "center", flex: "1 0 auto" }}>
                                                  Cliquez sur un mot pour le déselectionner
                                                </p>
                                              </Space>
                                            </Col>
                                            <Col span={10}>
                                              <Space direction="vertical">
                                                <Button
                                                    disabled={matchingStarted || resultExist}
                                                    type="primary"
                                                    size="large"
                                                    style={{ fontSize: "12px", textAlign: "center" }}
                                                    onClick={handlerClickSecondaryButton}
                                                    block
                                                >
                                                  Selectionner/Deselectionner tous les mots
                                                </Button>
                                                <Card
                                                    title="Mots secondaires :"
                                                    bodyStyle={
                                                      selectChoix
                                                          ? { background: "#FFFFFF" }
                                                          : { background: "#E8E8E8" }
                                                    }
                                                    //className={selectChoix ? "paper" : "paperActive"}
                                                    // style={{ width: "100%" }}
                                                    onClick={() => {
                                                      // this.setState({ selectChoix: false })
                                                      setSelectChoix(false);
                                                    }}
                                                >
                                                  {keywordsToDisplay.map((w, index) => {
                                                    if (w.disabledS)
                                                      return (
                                                          <Dropdown.Button
                                                              key={index}
                                                              icon={w.mots in synonymsToDisplay?<CaretDownOutlined />:null}
                                                              overlay={menu(w.mots)}
                                                              disabled={matchingStarted || resultExist}
                                                              style={{ margin: "5px" }}
                                                              onClick={() => {
                                                                // const { finalWords } = this.state;
                                                                //  let finalWordsloocal6 = finalWords;
                                                                let finalWordsloocal6 = keywordsToDisplay;
                                                                finalWordsloocal6[
                                                                    index
                                                                    ].disabledS = false;
                                                                finalWordsloocal6[
                                                                    index
                                                                    ].disabledP = false;
                                                                finalWordsloocal6[
                                                                    index
                                                                    ].checked = !finalWordsloocal6[index]
                                                                    .checked;


                                                                let consultanat_x_exist = Object.keys(consultant_xToDisplay).find(key => consultant_xToDisplay[key].includes(finalWordsloocal6[index].mots));
                                                                if(consultanat_x_exist){
                                                                  handleDiselectingChildOfConsultantX(finalWordsloocal6,consultanat_x_exist,finalWordsloocal6[index]);
                                                                }

                                                                // check if there is compound key words
                                                                if(finalWordsloocal6[index].mots.includes("-")){
                                                                  const splitCompoundWords = finalWordsloocal6[index].mots.split(/-(.+)/);
                                                                  if(splitCompoundWords[0].toLowerCase()==="consultant")handleKeyWordsRelatedToCompoundWords(finalWordsloocal6,splitCompoundWords,finalWordsloocal6[index])
                                                                }


                                                                // this.setState({ finalWords });
                                                                dispatch(setListKeywords(finalWordsloocal6));// setFinalWords(finalWordsloocal6);
                                                                setTest(test+1);
                                                              }}
                                                          >
                                                            {w.mots}
                                                          </Dropdown.Button>
                                                      );
                                                    return null;
                                                  })}
                                                </Card>
                                                <p style={{ textAlign: "center", flex: "1 0 auto" }}>
                                                  Cliquez sur un mot pour le déselectionner
                                                </p>
                                              </Space>
                                            </Col>
                                          </Row>
                                          {clicked && (
                                              <div>
                                                <Divider />
                                                <Row>
                                                  <Form
                                                      {...layout}
                                                      layout="vertical"
                                                      name="basic2"
                                                      onFinish={onFinish2}
                                                      onFinishFailed={onFinishFailed2}
                                                      style={{ width: "100%" }}
                                                  >

                                                    <Form.Item
                                                        label="ajouter d'autres mots clés"
                                                        name="keyWordsType"
                                                    >

                                                    <Select
                                                        defaultValue={newKeywordsType}
                                                        style={{ width: 400 }}
                                                        onChange={handleSelectChange}
                                                        options={[
                                                          { value: 'description', label: 'Description' },
                                                          { value: 'double', label: 'Mot composé Double' },
                                                          { value: 'triplet', label: 'Mot composé Triple' },
                                                        ]}
                                                    />

                                                    </Form.Item>
                                                    <Form.Item
                                                        name="keyWords"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: "Please add new keywords",
                                                          },
                                                        ]}
                                                    >
                                                      {newKeywordsType === "description"?<TextArea
                                                          rows={5}
                                                          placeholder=""
                                                          onChange={(e) => {
                                                            // this.setState({keyWords: e.target.value });
                                                            setKeyWords(e.target.value);
                                                          }}
                                                      />:  <Input
                                                          placeholder={newKeywordsType === "double"?"Un mot composé double comme data-science ou software-engineer...":
                                                              "Un mot composé triple comme chef-de-projet ou consultant-technico-fonctionnel... "}
                                                          onChange={(e) => {
                                                            setKeyWords(e.target.value);
                                                          }}
                                                      />}


                                                    </Form.Item>


                                                    <Form.Item>
                                                      <Button
                                                          type="primary"
                                                          size="large"
                                                          htmlType="submit"
                                                          loading={SpinnerMotCle}
                                                          disabled={matchingStarted || !keyWords}
                                                      >
                                                        Ajouter
                                                      </Button>
                                                    </Form.Item>
                                                  </Form>
                                                </Row>
                                              </div>
                                          )}
                                          <Divider />
                                          <Row justify="space-around">
                                            <Col span={10}>
                                              {newWord.length !== 0 && (
                                                  <div>
                                                    les mots qui seront pris en compte lors de la
                                                    prochaine mise à jour de dictionnaire :
                                                    <br />
                                                    {newWord.map((w) => {
                                                      return <Tag color="volcano">{w}</Tag>;
                                                    })}
                                                  </div>
                                              )}
                                            </Col>
                                            <Col span={10} />
                                          </Row>
                                          <Divider />
                                          <Row justify="space-around">
                                            <Col span={10}>
                                              <Button
                                                  disabled={clicked || matchingStarted || resultExist}
                                                  type="primary"
                                                  size="large"
                                                  style={{
                                                    fontSize: "12px",
                                                    textAlign: "center",
                                                    maxWidth: "200px",
                                                  }}
                                                  onClick={() => setClicked(true)}
                                                  block
                                              >
                                                Ajouter des mots clés
                                              </Button>
                                            </Col>
                                            <Col span={10}>
                                              <Button
                                                  disabled={matchingStarted || resultExist}
                                                  type="primary"
                                                  size="large"
                                                  style={{
                                                    fontSize: "12px",
                                                    textAlign: "center",
                                                    maxWidth: "200px",
                                                  }}
                                                  onClick={applyMatching}
                                                  block
                                              >
                                                Lancer le Matching
                                              </Button>
                                            </Col>
                                          </Row>
                                          <Divider />
                                          <Row>
                                            <div className={classes.chronometre}>
                                              <div className={classes.tim}>
                                                <span>{hours}</span>:<span>{minutes}</span>:
                                                <span>{seconds}</span>
                                              </div>
                                            </div>
                                          </Row>
                                          {matchingToDisplay.length >0 ? (<Row>
                                            <div className={classes.chronometre}>
                                              <div className={classes.tim2}>
                                                {totalFiltredCandidates ===0 && <div><span>{totalToDisplay}</span> Candidates</div>}
                                                {totalFiltredCandidates !==0 && <div style={{display: "flex", marginLeft: "25%"}}><span style={{display: "flex", "margin-right": "5px" }}><div style={{background: "yellow"}}>{totalFiltredCandidates}</div>/{totalToDisplay}</span> Candidates</div>}

                                              </div>
                                            </div>
                                          </Row>) : null}


                                          {matchingStarted && !matchingToDisplay && <Spin indicator={antIcon} />}
                                          {
                                            matchingToDisplay &&
                                            (matchingToDisplay.length !== 0 || resultExist) && (
                                                <Row>
                                                  <Col >
                                                    <Table
                                                        key={tableKey}
                                                        columns={columns}
                                                        dataSource={matchingToDisplay}
                                                        scroll={{ x: 2700, y: 600 }}
                                                        pagination={{
                                                          onChange: paginate,
                                                          total: totalFiltredCandidates?totalFiltredCandidates:totalToDisplay && !isFilter?totalToDisplay:0,
                                                          current: filters.skip / filters.limit + 1,
                                                          pageSize:filters.limit,
                                                          showSizeChanger: true
                                                        }}
                                                        loading={isLoadingPagination}
                                                        onChange={handleTableChange}

                                                    />
                                                  </Col>
                                                </Row>
                                            )}
                                        </div>
                                    )}
                              </div>
                          )}
                        </div>)}

                      </div>
                    </Content>
                    <button onClick={getForm} className={classes.rotate}><div className={classes.button1}>Votre avis </div>  <span className={classes.iconVertical} ><AliwangwangOutlined /></span></button>
                  </div>
                  <Footer />
                </Layout>
              </div>

            </div>
           :null}
       </div>

  );
}
