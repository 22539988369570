const actions = {
    SET_SECONDARYSKILLS_EXPRESS: "SET_SECONDARYSKILLS_EXPRESS",

    setSecondarySkillsExpress: (payload) => ({
        type: actions.SET_SECONDARYSKILLS_EXPRESS,
        payload,
    }),
};

export default actions;
