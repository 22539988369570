import actions from "./actions";

const initState = {
    total: 0,
};

export default function TotalReducer(state = initState, action) {
    if (action.type === actions.SET_TOTAL) {
        return {
            ...state,
            total: action.payload,
        };
    }
    return state;
}