import actions from "./actions";

const initState = {
    matchingStarted: false,
};

export default function MatchingStartedReducer(state = initState, action) {
    if (action.type === actions.SET_MATCHING_STARTED) {
        return {
            ...state,
            matchingStarted: action.payload,
        };
    }
    return state;
}