
import { Modal } from "antd";
import React from "react";
import './JobDescriptionModal.scss'

function JobDescriptionModal(props) {
  const {
    jobDescription,
    jobDescriptionTitle,
    jobDescriptionStatus,
    jobDescriptionSkills,
    visibleJobDescription,
    setVisibleJobDescription,
    res
  } = props;

  // Fonction pour supprimer les html balise
  const extractContent = (htmlString) => {
    const span = document.createElement("span");
    span.innerHTML = htmlString;
    return span.textContent || span.innerText;
  };
  const handleOk = (e) => {
    setVisibleJobDescription(false);
  };
  const handleCancel = (e) => {
    setVisibleJobDescription(false);
};
  return (
    <div>
      <Modal
        visible={visibleJobDescription}
        onOk={handleOk}
        cancelButtonProps={{ style: { display: "none" } }}
        style={{ top: 20, marginRight: 20, height: "calc(100vh - 200px)" }}
        width="40%"
        onCancel={handleCancel}
        className="custom-modal"
      >
        <div>
          <strong>
            <p>Title: </p>{" "}
          </strong>{" "}
          <p> {jobDescriptionTitle} </p>
          <strong>
            {" "}
            <p>Status:</p>{" "}
          </strong>{" "}
          <p> {jobDescriptionStatus} </p>
          <strong>
            {" "}
            <p> Required Skills: </p>{" "}
          </strong>{" "}
          <p> {jobDescriptionSkills}</p>
          <strong>
            {" "}
            <p>Description: </p>{" "}
          </strong>{" "}
         <p> {extractContent(jobDescription)}</p> 
        </div>
      </Modal>
    </div>
  );
}

export default JobDescriptionModal;
