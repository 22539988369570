import { config } from "dotenv";

config();
const { REACT_APP_URL } = process.env;

// node env restriction
const NODE_ENV = ["development", "production", "test"].includes(
  process.env.REACT_APP_NODE_ENV
)
  ? process.env.REACT_APP_NODE_ENV
  : "development";
const runtimeVars = {
  NODE_ENV,

  PORT: process.env.PORT || 3000,
  URL: REACT_APP_URL ||"https://smatch.back.club-freelance.fr"


};

export default runtimeVars;
