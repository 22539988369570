import actions from "./actions";

const initState = {
    totalExpress: 0,
};

export default function TotalExpressReducer(state = initState, action) {
    if (action.type === actions.SET_TOTAL_EXPRESS) {
        return {
            ...state,
            totalExpress: action.payload,
        };
    }
    return state;
}