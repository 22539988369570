import { config } from "dotenv";

config();
const { REACT_APP_URL_BACK_PYTHON } = process.env;

// node env restriction
const NODE_ENV = ["development", "production", "test"].includes(
  process.env.REACT_APP_NODE_ENV
)
  ? process.env.REACT_APP_NODE_ENV
  : "development";
const runtimeVarsPython = {
  NODE_ENV,
  PORT: process.env.PORT || 5000,  
  URL: REACT_APP_URL_BACK_PYTHON ||"https://smatch.back.club-freelance.fr"
};

export default runtimeVarsPython;
