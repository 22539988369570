const actions = {
    SET_TOTAL_EXPRESS: "SET_TOTAL_EXPRESS",

    setTotalExpress: (payload) => ({
        type: actions.SET_TOTAL_EXPRESS,
        payload,
    }),
};

export default actions;
