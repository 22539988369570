const actions = {
    SET_LIST_SYNONYMS: "SET_LIST_SYNONYMS",

    setListSynonyms: (payload) => ({
        type: actions.SET_LIST_SYNONYMS,
        payload,
    }),
};

export default actions;
