import parse from 'html-react-parser';
import { Modal } from "antd";
import React from "react";

const options = {
    replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === 'remove') {
            return <></>;
        }
    },
};

function ResumeModal(props) {
    const {  visible, setVisible, resume, name , id } = props;


    const handleOk = (e) => {
        setVisible(false);

    };
    const handleCancel = (e) => {
        setVisible(false);
    };

    return (
        <div>
            <Modal
                visible={visible}
                title={id}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                style={{ top: 20, marginRight: 20, height: 'calc(100vh - 200px)' }}
                width="40%"
                          >


                {resume?parse(resume, options): null}


            </Modal>
        </div>
    );
}

export default ResumeModal;
