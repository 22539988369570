import axios from "axios";

const request = async (method, url, body, search) => {
  const axiosConfig = {
    method,
    url,
    data: body,
    params: search,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Accept: "application/json",
    },
  };
  return axios(axiosConfig);
};

export default request;
