import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Input,
  Row,
  Col,
  Button,
  Card,
  Divider,
  Space,
  Modal,
  Table,
  message,
  Tooltip,
} from "antd";
import {
  SearchOutlined,
  RedoOutlined,
  FilePdfOutlined,
  InfoCircleTwoTone,
  FlagOutlined,
  AliwangwangOutlined,
} from "@ant-design/icons";

import "antd/dist/antd.css";
import { useDispatch, useSelector } from "react-redux";
import matchingSmatchActions from "../../redux/redux-smatchVacancy/matchingSmatchVacancy/actions";
import primarySkillsSmatchVacancy from "../../redux/redux-smatchVacancy/primarySkillsSmatchVacancy/actions";
import secondarySkillsSmatchVacancy from "../../redux/redux-smatchVacancy/secondarySkillsSmatchVacancy/actions";
import idJobOrderExpressActions from "../../redux/idJobOrderExpress/actions";
import isSyncExpressActions from "../../../src/redux/is_SyncExpress/actions";
import resultExistActions from "../../redux/resultExist/actions";
import classes from "./HomePageSmatchVacancy.module.scss";
import FormModal from "../Form/FormModal";
import requestDataSmatchVacancy from "../../services/smatchVacancyServices";
import ResumeModal from "../CandidateResume/ResumeModal";
import JobDescriptionModal from "../CandidateResume/JobDescriptionModal";

const { Header, Footer, Content } = Layout;

// redux actions

const {
  setMatchingSmatchVacancy,
  setTotalVacancyReducer,
  setTotalFiltersReducer,
  resetFilterByIndex,
  setTotalElementReducer,
  setFilters,
  setIsSyncSmatchvac
} = matchingSmatchActions;

const { setPrimarySkillsSmatchVacancy } = primarySkillsSmatchVacancy;
const { setSecondarySkillsSmatchVacancy } = secondarySkillsSmatchVacancy;
const { setResultExist } = resultExistActions;
const { setIdJobOrderExpress } = idJobOrderExpressActions;


export default function HomePageSmatchVacancy(props) {
  // Redux state
  const dispatch = useDispatch();
  // Utilisation de useSelector pour accéder au state Redux
  const vacanciesToDisplay = useSelector(
    (state) => state.MatchingVacanciesListReducer.listMatchingVacancies
  );

  const totalToDisplay = useSelector(
    (state) => state.MatchingVacanciesListReducer.totalvac
  );

  const primarySkillsToDisplay = useSelector(
    (state) => state.primarySkillsSmatchVacancyReducer.primarySkills
  );

  const secondaryskillsToDisplay = useSelector(
    (state) => state.secondarySkillsSmatchVacancyReducer.secondarySkills
  );

  const totalFiltredVacancies = useSelector(
    (state) => state.MatchingVacanciesListReducer.totalfilter
  );

  const idJobOrderToSend = useSelector(
    (state) => state.IdJobOrderExpressReducer.idJobOrderExpress
  );
  const resultExist = useSelector(
    (state) => state.ResultExistReducer.resultExist
  );
  const filters = useSelector(
    (state) => state.MatchingVacanciesListReducer.filters
  );

  const initialFilters = useSelector(
    (state) => state.MatchingVacanciesListReducer.filters
  );

  // Component state
  const [TotalPage, setTotalPage] = useState(10);
  const [matchingStarted, setMatchingStarted] = useState(false);
  const [totalPagination, setTotalPagination] = useState(0);
  const [resultApi, setResultApi] = useState([]);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [visible, setVisible] = useState(false);
  const dataIndexRef = useRef();
  const [selectedkeyRef, setSelectedkeyRef] = useState();
  const [visibleResume, setVisibleResume] = useState(false);
  const [resume, setResume] = useState();
  const [resumeId, setResumeId] = useState();
  const [tableKey, setTableKey] = useState(0);
  const [isFilter, setIsFilter] = useState(false);
  const [visibleJobDescription,setVisibleJobDescription] = useState(false);
  const [jobDescriptionTitle,setTitleDescription] = useState();
  const [jobDescriptionStatus,setStatusJobDescription] = useState();
  const [jobDescriptionSkills,setSkillsJobDescription] = useState();
  const [jobDescription,setJobDescription] = useState();
  // Fonction pour vérifier si l'application est ouverte dans Bullhorn
  const checkIfInBH = () => {
    try {
      const BhUrl = window.location.href;
      const BhUrlSplit3 = BhUrl.split("EntityID=");
      const BhUrlSplit4 = BhUrlSplit3[1].split("&");
      return BhUrlSplit4[0];
    } catch (e) {
      return null;
    }
  };
  // Fonction pour obtenir l'utilisateur connecté dans Bullhorn
  const getConnectedUserIfInBH = () => {
    try {
      const BhUrl = window.location.href;
      const BhUrlSplit5 = BhUrl.split("UserID=");
      const BhUrlSplit6 = BhUrlSplit5[1].split("&");
      return BhUrlSplit6[0];
    } catch (e) {
      return null;
    }
  };

  // Obtention de l'ID du vacancy depuis Bullhorn
  let vacancyID = checkIfInBH();

  const BHUser = getConnectedUserIfInBH();
  //  /**useEffect**/ pour traiter le changement du vacancyID
  useEffect(async () => {
    if (vacancyID) {
      // we are in BH
      await dispatch(setIdJobOrderExpress(vacancyID));
      if (vacancyID !== idJobOrderToSend && parseInt(idJobOrderToSend)) {
        await window.localStorage.removeItem("state");
        await window.location.reload();
        dispatch(setTotalVacancyReducer(0));
      }
    }
  }, []);
  // Fonction de pagination
  const paginate = (page) => {
    setIsLoadingPagination(true);
    const newState = { ...filters };
    newState.skip = (page - 1) * TotalPage;
    newState.searchTrigger = true;
    dispatch(setFilters(newState));
  };

// job order is still not synchronized in bh-data, 
  const warningChoices = () => {
    Modal.warning({
        title: 'Alerte',
        content: (<div>     This mission is currently being processed! <br/>    Please review it later. </div>),
    });
};
// job order is a Fake 
const warningFakeOffert = () => {
    Modal.warning({
        title: 'Alerte',
        content: 'This is a test offer! Please choose another one.',
        
    });
};

  // Fonction pour vériier le type de vacancyID et candidateID (string or array )
  const checkType = (data) => {
    if(data && typeof data === "string") return data.split(" ").map((item)=>parseInt(item, 10) );
    else if (data && typeof data === "object") return data.flat(Infinity)[0].split(" ").map((item)=>parseInt(item, 10) );
    else return null;
}
  // Fonction pour obtenir les résultats de la requête backend
  const getResult = async () => {
    try {
      if (vacancyID) {
        const glob = await requestDataSmatchVacancy(
          "post",
          `/match_vacancies`,
          {
            job_id: parseInt(vacancyID) || null,
            limit: filters.limit,
            skip: filters.skip,
            bh_user: BHUser,
            search_engine: "smatch-vacancy",
            filters: JSON.stringify({
              vacancy_id: checkType(filters.vacancy_id),
              score: parseInt(filters.score, 10),
              employmentType: filters.employmentType,
              name: filters.name,
              email: filters.email,
              id: checkType(filters.id),
              status: filters.status,
              location: filters.location,
              occupation: filters.occupation,
              recruitment_status: filters.recruitment_status,
              employmentPreference: filters.employmentPreference,
              date_added: filters.date_added,
              sortVacancyId: filters.sortVacancyId,
              sortScore: filters.sortScore,
              sortDate: filters.sortDate,
            }),
          }
        );
       
        if (glob) {
          if(!glob.isSync)  warningChoices();
          if(glob.isFake) warningFakeOffert();
          setResultApi(glob.vacancies);
          dispatch(setResultExist(true));
          dispatch(setPrimarySkillsSmatchVacancy(glob.primary_skills));
          dispatch(setSecondarySkillsSmatchVacancy(glob.secondary_skills));
          dispatch(setIsSyncSmatchvac(glob.isSync));
          setTotalPagination(glob.total);
          setIsLoadingPagination(false);
      
          if (totalToDisplay === 0) {
            dispatch(setTotalVacancyReducer(glob.total));
          }
          dispatch(setMatchingSmatchVacancy(glob.vacancies));
          dispatch(setTotalElementReducer(glob.total));
          if (totalToDisplay !== glob.total && totalToDisplay !== 0) {
            dispatch(setTotalFiltersReducer(glob.total));
            setIsFilter(true);
          } else {
            dispatch(setTotalFiltersReducer(0));
          }
        }
      }
    } catch (e) {
      console.log("error =>", e);
    }
  };

  useEffect(() => {
    if (vacanciesToDisplay && vacanciesToDisplay.length > 0) {
      dispatch(setResultExist(true));
    }
  }, [vacanciesToDisplay]);

  // charger les résultats de la requête selon les filtres
  useEffect(() => {
    setMatchingStarted(true);
    dispatch(setMatchingSmatchVacancy([]));
    getResult();
  }, [filters]);

  // Fonction de recherche dans le tableau
  const searchQuery = (dataIndex, value, confirm) => {
    confirm();
    setIsLoadingPagination(true);
    let FiltersToSend = { ...filters };
    FiltersToSend = {
      ...FiltersToSend,
      [dataIndex]: value,
      skip: 0,
    };
    dispatch(setFilters(FiltersToSend));
    dataIndexRef.current = null;
  };
  // Fonction pour gérer le tri(asc/desc) dans le tableau
  const handleTableChange = (pagination, data, sorter, a) => {
    let defaultOrder = null;
    if (sorter.order === "ascend") {
      defaultOrder = 1;
    }
    if (sorter.order === "descend") {
      defaultOrder = -1;
    }
    if (sorter && sorter.order) {
      setIsLoadingPagination(true);
      if (sorter.field === "score") {
        if ((pagination.current - 1) * 10 === filters.skip) {
          dispatch(
            setFilters({
              ...filters,
              sortScore: defaultOrder,
              sortDate: null,
              sortVacancyId: null,
              skip: 0,
              limit: pagination.pageSize,
            })
          );
        } else {
          dispatch(
            setFilters({
              ...filters,
              sortScore: defaultOrder,
              sortDate: null,
              sortVacancyId: null,
              limit: pagination.pageSize,
              skip: (pagination.current - 1) * pagination.pageSize,
            })
          );
        }
      }
      if (sorter.field === "date_added") {
        if ((pagination.current - 1) * 10 === filters.skip) {
          dispatch(
            setFilters({
              ...filters,
              sortDate: defaultOrder,
              sortScore: null,
              sortVacancyId: null,
              skip: 0,
              limit: pagination.pageSize,
            })
          );
        } else {
          dispatch(
            setFilters({
              ...filters,
              sortDate: defaultOrder,
              sortScore: null,
              sortVacancyId: null,
              limit: pagination.pageSize,
              skip: (pagination.current - 1) * pagination.pageSize,
            })
          );
        }
      }
      if (sorter.field === "vacancy_id") {
        if ((pagination.current - 1) * 10 === filters.skip)
          dispatch(
            setFilters({
              ...filters,
              sortVacancyId: defaultOrder,
              sortScore: null,
              sortDate: null,
              skip: 0,
              limit: pagination.pageSize,
            })
          );
        else
          dispatch(
            setFilters({
              ...filters,
              sortVacancyId: defaultOrder,
              sortScore: null,
              sortDate: null,
              limit: pagination.pageSize,
              skip: (pagination.current - 1) * pagination.pageSize,
            })
          );
      }
    } else if (pagination && pagination.pageSize) {
      setTotalPage(pagination.pageSize);
      dispatch(
        setFilters({
          ...filters,
          limit: pagination.pageSize,
          skip: (pagination.current - 1) * pagination.pageSize,
        })
      );
    }
  };
  // Fonction pour faire la recherche ou la reinitialisation du filtre
  const Search = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
      confirm,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search by ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value)}
            onPressEnter={() => searchQuery(dataIndex, selectedKeys, confirm)}
            style={{ marginBottom: 10, display: "flex" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                searchQuery(dataIndex, selectedKeys, confirm);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 80 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
                setIsLoadingPagination(true);
                dispatch(resetFilterByIndex(dataIndex)); // Utilisez l'action pour réinitialiser le filtre par son dataIndex
                dispatch(setTotalFiltersReducer(0));
                setIsFilter(false);
                setTotalPagination(0);
                setSelectedkeyRef("");
              }}
              size="small"
              style={{ width: 80 }}
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                setIsLoadingPagination(true);
                dispatch(setFilters({ limit: 10, skip: 0 }));
                dispatch(setTotalFiltersReducer(0));
                setTotalPagination(0);
                setSelectedkeyRef("");
                setTableKey((tableKey) => tableKey + 1);
                setIsFilter(false);
              }}
              size="small"
              style={{ width: 80 }}
            >
              Reset All
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) => text,
  });

  // Fonction pour ouvrir et afficher dans un popup la description du vacancy
  const onClickOverviewJobDescription = async (id) => {
    const res =
      await requestDataSmatchVacancy("POST", `/job_description`, {
        job_id: id,
      });
    if (res ) {
      setVisibleJobDescription(true);
      setTitleDescription(res.title);
      setStatusJobDescription(res.status);
      setSkillsJobDescription(res.skills);
      setJobDescription(res.description);
    }
  };
  // Fonction pour ouvrir et afficher le cv
  const onClickOverviewCV = async (id) => {
    const res = await requestDataSmatchVacancy(
      "POST",
      `/candidate_cv_preview`,
      { id: id }
    );
    if (res && res.length > 0) {
      // Vérifie si res existe et s'il contient au moins un élément
      const { last_cv, id } = res[0]; // Accédez au premier élément de res

      if (!last_cv || last_cv === "Non renseigné") {
        Modal.warning({
          title: "Aucun cv trouvé ",
        });
      } else {
        setResume(last_cv);
        setResumeId(id);
        setVisibleResume(true);
      }
    } else {
      Modal.warning({
        title: "Aucun cv trouvé ",
      });
    }
  };

  const columns = [
    {
      title: "Vacancy",
      dataIndex: "vacancy_id",
      key: "vacancy_id",
      sorter: true,
      filteredValue: filters.vacancy_id && [filters.vacancy_id],
      ...Search("vacancy_id"),
      fixed: "left",
      width: "8%",
      render: (resultApi) => {
        return (
          <div style={{ display: "inline-flex" }}>
            <a
              href={
                "https://cls22.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=" +
                resultApi
              }
              target="_blank"
              rel="noreferrer noopener"
              style={{ marginRight: "20%" }}
            >
              {resultApi}
            </a>
            <Tooltip title="Click to view vacancy details" color="#1890ff">
              <InfoCircleTwoTone
                style={{
                  color: "#1890ff",
                  fontSize: "18px",
                  marginRight: "20%",
                }}
                onClick={() => onClickOverviewJobDescription(resultApi)}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      filteredValue: filters.score && [filters.score],
      sorter: true,
      ...Search("score"),
      width: "5%",
      render: (resultApi) => {
        return <div>{resultApi}</div>;
      },
    },
    {
      title: "Date added of the vacancy",
      dataIndex: "date_added",
      key: "date_added",
      filteredValue: filters.date_added && [filters.date_added],
      sorter: true,
      //...Search("date_added"),
      width: "9%",
      render: (resultApi) => {
        return <div>{resultApi}</div>;
      },
    },
    {
      title: "Contract type",
      dataIndex: "employmentType",
      sorter: false,
      key: "employmentType",
      ...Search("employmentType"),
      filteredValue: filters.employmentType && [filters.employmentType],
      width: "10%",
      render: (resultApi) => {
        return <div>{resultApi}</div>;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      filteredValue: filters.location && [filters.location],
      ...Search("location"),
      width: "8%",
      render: (resultApi) => {
        return <div>{resultApi}</div>;
      },
    },
    {
      title: "Candidate ID & CV",
      dataIndex: "candidate",
      key: "candidate",
      filteredValue: filters.id && [filters.id],
      ...Search("id"),
      width: "8%",
      render: (candidate) => {
        return (
          <div
            style={{ display: "inline-flex", "padding-left": "10%" }}
            key={candidate.id}
          >
            <a
              href={
                "https://cls22.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=" +
                candidate.id
              }
              target="_blank"
              rel="noreferrer noopener"
              style={{ marginRight: "20%" }}
            >
              {candidate.id}
            </a>
            <div>
              {candidate?.cv_type !== " CV Mindquest" ? (
                <Tooltip title="Dernier CV " color="#1890ff">
                  <FilePdfOutlined
                    style={{
                      color: "#1890ff",
                      fontSize: "18px",
                      marginRight: "20%",
                    }}
                    onClick={() => onClickOverviewCV(candidate.id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title="Dernier CV Mindquest"
                  color="#1890ff"
                  //key={entity["candidate"].id}
                >
                  <img
                    style={{
                      verticalAlign: "middle",
                      height: "30px",
                      marginRight: "15%",
                    }}
                    src="../../mq.png"
                    onClick={() => onClickOverviewCV(candidate.id)}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Candidate Name",
      dataIndex: "candidate",
      sorter: false,
      key: "name",
      filteredValue: filters.name && [filters.name],
      ...Search("name"),
      width: "8%",
      render: (candidate) => {
        return <div key={candidate.name}>{candidate.name}</div>;
      },
    },
    {
      title: "Candidate Job Title",
      dataIndex: "candidate",
      sorter: false,
      key: "occupation",
      filteredValue: filters.occupation && [filters.occupation],
      ...Search("occupation"),
      width: "10%",
      render: (candidate) => {
        return <div key={candidate.name}>{candidate.occupation}</div>;
      },
    },
    {
      title: "How far did the candidate go within the process",
      dataIndex: "candidate",
      key: "recruitment_status",
      ...Search("recruitment_status"),
      filteredValue: filters.recruitment_status && [filters.recruitment_status],
      width: "11%",
      render: (candidate) => {
        return (
          <div style={{ display: "inline-flex" }}>
            <div style={{ "padding-right": "7px" }}>
              {candidate.recruitment_status}
            </div>
            <Tooltip
              title={
                candidate.priority === 1
                  ? "Placed by us"
                  : candidate.priority === 2
                  ? "Interview"
                  : candidate.priority === 3
                  ? "CV Sent"
                  : candidate.priority === 4
                  ? "Shortlisted"
                  : ""
              }
              color={
                candidate.priority === 1
                  ? "#32CD32"
                  : candidate.priority === 2
                  ? "#FF8C00"
                  : candidate.priority === 3
                  ? "#FDE30E"
                  : candidate.priority === 4
                  ? "#FF0000"
                  : "#FFFFFF"
              }
              //key={entity["candidate"].id}
              overlayStyle={{ maxWidth: "500px" }}
            >
              <FlagOutlined
                style={{
                  color:
                    candidate.priority === 1
                      ? "#32CD32"
                      : candidate.priority === 2
                      ? "#FF8C00"
                      : candidate.priority === 3
                      ? "#FDE30E"
                      : "#FF0000",
                  fontSize: "18px",
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: "candidate",
      key: "email",
      ...Search("email"),
      filteredValue: filters.email && [filters.email],
      width: "13%",
      render: (candidate) => {
        return (
          <div>
            <div>{candidate.email}</div>
            <div>{candidate.phone}</div>
          </div>
        );
      },
    },
    {
      title: "Candidate Status",
      dataIndex: "candidate",
      key: "status",
      filteredValue: filters.status && [filters.status],
      ...Search("status"),
      width: "8%",
      render: (candidate) => {
        return <div>{candidate.status}</div>;
      },
    },
    {
      title: "Employment preference",
      dataIndex: "candidate",
      sorter: false,
      key: "employmentPreference",
      filteredValue: filters.employmentPreference && [
        filters.employmentPreference,
      ],
      ...Search("employmentPreference"),
      width: "7%",
      render: (candidate) => {
        return <div>{candidate.employmentPreference}</div>;
      },
    },
  ];

  const reload = () => {
    try {
      localStorage.removeItem("state");
      window.location.reload();
    } catch (e) {
      message.error("Please enable cookies for private navigation.");
    }
  };

  const getForm = () => {
    setVisible(true);
  };

  return (
    <div>
      {vacancyID ? (
        <div>
          <FormModal visible={visible} setVisible={setVisible} />
          <ResumeModal
            visible={visibleResume}
            setVisible={setVisibleResume}
            resume={resume}
            id={resumeId}
          />
           <JobDescriptionModal
            visibleJobDescription={visibleJobDescription}
            setVisibleJobDescription={setVisibleJobDescription}
            res={jobDescription}
            jobDescriptionTitle={jobDescriptionTitle}
            jobDescriptionStatus={jobDescriptionStatus}
            jobDescriptionSkills={jobDescriptionSkills}
            jobDescription={jobDescription}
          />
          <FormModal visible={visible} setVisible={setVisible} />
          <div className={classes.HomePage}>
            <Layout>
              <Header>
                <div className={classes.logo}>
                  <input
                    type="image"
                    src="/logo192.png"
                    width="48"
                    height="48"
                    alt=""
                  />
                </div>
              </Header>
              <div className={classes.centre}>
                <Content style={{ padding: "70px 150px 0px 150px" }}>
                  <div className={classes.siteLayout}>
                    <RedoOutlined className={classes.icon} onClick={reload} />
                    <br />
                    <br />

                    <div>
                      <div>
                        <Divider />
                        <Row justify="space-around">
                          <Col span={10}>
                            <Space direction="vertical">
                              <Card title="Mots primaires" type="inner">
                                {primarySkillsToDisplay.map((w, index) => {
                                  return (
                                    <Button
                                      key={index}
                                      disabled={matchingStarted || resultExist}
                                      style={{ margin: "5px" }}
                                    >
                                      {w}
                                    </Button>
                                  );
                                })}
                              </Card>
                            </Space>
                          </Col>
                          <Col span={10}>
                            <Space direction="vertical">
                              <Card title="Mots secondaires " type="inner">
                                {secondaryskillsToDisplay.map((w, index) => {
                                  return (
                                    <Button
                                      key={index}
                                      disabled={matchingStarted || resultExist}
                                      style={{ margin: "5px" }}
                                    >
                                      {w}
                                    </Button>
                                  );
                                })}
                              </Card>
                            </Space>
                          </Col>
                        </Row>
                        <br />
                        <Divider />
                        <br />

                        {vacanciesToDisplay.length > 0 ? (
                          <Row>
                            <div className={classes.chronometre}>
                              <div className={classes.tim2}>
                                {totalFiltredVacancies === 0 && (
                                  <div>
                                    <span>{totalToDisplay} </span> Job Submissions
                                  </div>
                                )}
                                {totalFiltredVacancies !== 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "25%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        "margin-right": "5px",
                                      }}
                                    >
                                      <div style={{ background: "#77B5FE" }}>
                                        {totalFiltredVacancies}
                                      </div>
                                      /{totalToDisplay}
                                    </span>{" "}
                                    Job Submissions
                                  </div>
                                )}
                              </div>
                            </div>
                          </Row>
                        ) : null}
                        <br />
                        {resultApi &&
                          (resultApi.length !== 0 ||
                            vacanciesToDisplay ||
                            resultExist) && (
                            <Row>
                              <Col>
                                <Table
                                  columns={columns}
                                  key={tableKey}
                                  dataSource={vacanciesToDisplay}
                                  scroll={{ x: 2000, y: 600 }}
                                  pagination={{
                                    onChange: paginate,
                                    total: totalFiltredVacancies
                                      ? totalFiltredVacancies
                                      : totalToDisplay && !isFilter
                                      ? totalToDisplay
                                      : 0,
                                    current: filters.skip / filters.limit + 1,
                                    pageSize: filters.limit,
                                    showSizeChanger: true
                                  }}
                                  loading={isLoadingPagination}
                                  onChange={handleTableChange}
                                />
                              </Col>
                            </Row>
                          )}
                      </div>
                    </div>
                  </div>
                </Content>
                <button onClick={getForm} className={classes.rotate}>
                  <div className={classes.button1}>Votre avis </div>{" "}
                  <span className={classes.iconVertical}>
                    <AliwangwangOutlined />
                  </span>
                </button>
              </div>
              <Footer />
            </Layout>
          </div>
        </div>
      ) : null}
    </div>
  );
}
