const actions = {
    SET_ALLKEYWORDS_EXPRESS: "SET_ALLKEYWORDS_EXPRESS",

    setAllKeyWordsExpress: (payload) => ({
        type: actions.SET_ALLKEYWORDS_EXPRESS,
        payload,
    }),
};

export default actions;
