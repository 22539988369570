import actions from "./actions";

const initState = {
    listMatchingVacancies: [],
    totalvac: 0,
    totalfilter: 0,
    totalelement: 0,
        filters: {
            limit: 10,
            skip: 0,
        },
    isSyncSmatchVac: true,
};

export default function MatchingVacanciesListReducer(state = initState, action) {
    // modifier list vacancy
    if (action.type === actions.SET_LIST_MATCHING_VACANCIES) {
        return {
            ...state,
            listMatchingVacancies: action.payload
        };
    }
    // modifier total vacancy
    if (action.type === actions.SET_TOTAL_VACANCIES) {
        return {
            ...state,
            totalvac: action.payload
        };
    }
    // modifier total filter
    if (action.type === actions.SET_TOTAL_FILTERS) {
        return {
            ...state,
            totalfilter: action.payload
        };
    }
    // modif total element
    if (action.type === actions.SET_TOTAL_ELEMENT) {
        return {
            ...state,
            totalelement: action.payload
        };
    }
    // modif filter
        if (action.type === actions.SET_FILTERS) {
            return {
                ...state,
                filters: action.payload,
            };
        }
        if (action.type === actions.RESET_FILTER_BY_INDEX) {
            const { dataIndex } = action;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [dataIndex]: action.payload,
                }
            };
        }
        if (action.type === actions.SET_IS_SYNC_SMATCHVAC) {
            return {
                ...state,
                isSyncSmatchVac: action.payload,
            };
        }

        return state;
    }

