import actions from "./actions";

const initState = {
    isSyncExpress: true,
};

export default function IsSyncExpressReducer(state = initState, action) {
    if (action.type === actions.SET_IS_SYNC_EXPRESS) {
        return {
            ...state,
            isSyncExpress: action.payload,
        };
    }
    return state;
}