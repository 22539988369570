import actions from "./actions";

const initState = {
    primarySkills: [],
};

export default function primarySkillsSmatchVacancyReducer(state = initState, action) {
    if (action.type === actions.SET_PRIMARYSKILLS) {
        return {
            ...state,
            primarySkills: action.payload,
        };
    }
    return state;
}
