
import { Modal, Typography, Table, message } from "antd";
import React, { useState } from "react";



const { Title } = Typography;

function FormModal(props) {
    const {  visible, setVisible } = props;


    const handleOk = (e) => {
        setVisible(false);

    };
    const handleCancel = (e) => {
        setVisible(false);
    };




    return (
        <div>
            <Modal
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                width="60%"

            >
             <iframe width="100%" height= "600px" src= "https://forms.office.com/Pages/ResponsePage.aspx?id=__MEfkdzvEWErRo52fbLlGXxrRB_qDBBo4AyblkL2PpUMzBBWEc2S1JQOFc5SDlGS0M5S0RHUVJMWS4u&embed=true" frameborder= "0" marginwidth= "0" marginheight= "0" ></iframe>
            </Modal>
        </div>
    );
}

export default FormModal;
