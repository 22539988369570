const actions = {
    SET_LIST_CONSULTANT_X: "SET_LIST_CONSULTANT_X",

    setListConsultant_x: (payload) => ({
        type: actions.SET_LIST_CONSULTANT_X,
        payload,
    }),
};

export default actions;
