const actions = {
  SET_LIST_MATCHING: "SET_LIST_MATCHING",

  setMatching: (payload) => ({
    type: actions.SET_LIST_MATCHING,
    payload,
  }),
};

export default actions;
