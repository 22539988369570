const actions = {
    SET_TOTAL: "SET_TOTAL",

    setTotal: (payload) => ({
        type: actions.SET_TOTAL,
        payload,
    }),
};

export default actions;
