const actions = {
    SET_CATEGORIES: "SET_CATEGORIES",

    setCategories: (payload) => ({
        type: actions.SET_CATEGORIES,
        payload,
    }),
};

export default actions;
