import actions from "./actions";

const initState = {
    secondarySkillsExpress: [],
};

export default function SecondarySkillsExpressReducer(state = initState, action) {
    if (action.type === actions.SET_SECONDARYSKILLS_EXPRESS) {
        return {
            ...state,
            secondarySkillsExpress: action.payload,
        };
    }
    return state;
}
