const actions = {
    SET_LIST_MATCHING_VACANCIES: "SET_LIST_MATCHING_VACANCIES",
    SET_TOTAL_VACANCIES: "SET_TOTAL_VACANCIES",
    SET_TOTAL_FILTERS: "SET_TOTAL_FILTERS",
    SET_TOTAL_ELEMENT: "SET_TOTAL_ELEMENT",
    SET_FILTERS: "SET_FILTERS",
    RESET_FILTER_BY_INDEX: "RESET_FILTER_BY_INDEX",
    SET_IS_SYNC_SMATCHVAC: "SET_IS_SYNC_SMATCHVAC",
    setMatchingSmatchVacancy: (payload) => ({
        type: actions.SET_LIST_MATCHING_VACANCIES,
        payload,
    }),
    setTotalVacancyReducer: (payload) => ({
        type: actions.SET_TOTAL_VACANCIES,
        payload,
    }),
    setTotalFiltersReducer: (payload) => ({
        type: actions.SET_TOTAL_FILTERS,
        payload,
    }),
    setTotalElementReducer: (payload) => ({
        type: actions.SET_TOTAL_ELEMENT,
        payload,
    }),
    setFilters: (payload) => ({
        type: actions.SET_FILTERS,
        payload,
    }),
     // Ajoutez une action pour réinitialiser un filtre par son dataIndex
    resetFilterByIndex: (dataIndex) => ({
        type: actions.RESET_FILTER_BY_INDEX,
        dataIndex,
    }),
    setIsSyncSmatchvac: (payload) => ({
        type: actions.SET_IS_SYNC_SMATCHVAC,
        payload,
    }),
};

export default actions;
