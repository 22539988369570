import React, {useState, useEffect, useRef} from "react";
import {
    Form,
    Layout,
    Input,
    Row,
    Col,
    Button,
    InputNumber,
    Spin,
    Collapse,
    Card,
    Divider,
    Space,
    Modal,
    Tag,
    Table,
    DatePicker,
    message,
    Menu,
    Dropdown,

} from "antd";

import { DownOutlined, StopOutlined, MinusOutlined, CaretDownOutlined  } from '@ant-design/icons';

import {
    LoadingOutlined,
    SearchOutlined,
    FlagTwoTone,
    RedoOutlined,
    AliwangwangOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "antd/dist/antd.css";
import {useDispatch, useSelector, useStore} from "react-redux";
import listKeywordsActions from "../../../redux/keywords/actions";
import listSynonymsActions from "../../../redux/synonyms/actions";
import descriptionActions from "../../../redux/description/actions";
import zipcodeActions from "../../../redux/zipcode/actions";
import matchingActions from "../../../redux/matching/actions";
import matchingExpressActions from "../../../redux/matchingExpress/actions";
import totalExpressActions from "../../../redux/totalExpress/actions";
import primarySkillsExpressActions from "../../../redux/primaryskillsExpress/actions";
import secondarySkillsExpressActions from "../../../redux/secondaryskillsExpress/actions";
import allKeyWordsExpressActions from "../../../redux/AllKeywordsExpress/actions";
import isSyncExpressActions from "../../../redux/is_SyncExpress/actions";
import idJobOrderExpressActions from "../../../redux/idJobOrderExpress/actions";
import classes from "./HomePageExpress.module.scss";
import requestData from "../../../services/smatchServices";
import moment from "moment";
import FormModal from "../../Form/FormModal";
import Icon from "@ant-design/icons/es";
import totalActions from "../../../redux/total/actions";
import titleActions from "../../../redux/title/actions";
import idJobOrderActions from "../../../redux/idJobOrder/actions";
import IdJobOrderReducer from "../../../redux/idJobOrder/reducer";
import SynonymsListReducer from "../../../redux/synonyms/reducer";
//import {Logger} from "sass";
const { Header, Footer, Content } = Layout;
const { TextArea } = Input;
const { Panel } = Collapse;






const {setMatchingExpress}= matchingExpressActions;
const {setTotalExpress} = totalExpressActions;
const { setPrimarySkillsExpress } = primarySkillsExpressActions;
const {setSecondarySkillsExpress} = secondarySkillsExpressActions;
const {setAllKeyWordsExpress } = allKeyWordsExpressActions;
const {setIsSyncExpress} = isSyncExpressActions;
const {setIdJobOrderExpress}= idJobOrderExpressActions;


// to delete html balise
const extractContent = (htmlString) => {
    const span = document.createElement('span');
    span.innerHTML = htmlString;
    return span.textContent || span.innerText;
};



export default function HomePageExpress(props) {

    const dispatch = useDispatch();

      const candidatesToDisplay = useSelector(
        (state) => state.MatchingExpressListReducer.listMatchingExpress
    );

    const totalToDisplay = useSelector(
        (state) => state.TotalExpressReducer.totalExpress
    );

    const primaryskillsToDisplay = useSelector(
        (state) => state.PrimarySkillsExpressReducer.primarySkillsExpress
    );

    const secondaryskillsToDisplay = useSelector(
        (state) => state.SecondarySkillsExpressReducer.secondarySkillsExpress
    );

    const AllKeywordsToDisplay = useSelector(
        (state) => state.AllKeyWordsExpressReducer.allKeyWordsExpress
    );

    const is_SyncToDisplay = useSelector(
        (state) => state.IsSyncExpressReducer.isSyncExpress
    );

    const idJobOrderToSend = useSelector(
        (state) => state.IdJobOrderExpressReducer.idJobOrderExpress
    );


    const initialFilters = {
        limit: 10,
        skip: 0,
    };


    const [filters, setFilters] = useState(initialFilters);
    const [TotalPage, setTotalPage] = useState(10);
    const [matchingStarted, setMatchingStarted] = useState(false);
    const [totalFiltredCandidates, setTotalFiltredCandidates] = useState(0);
    const [totalPagination, setTotalPagination] = useState(0);
    const [resultExist, setResultExist] = useState(false);
    const [relancement, setRelancement] = useState(0);
    const [isLoadingPagination, setIsLoadingPagination] = useState(false);
    const [visible, setVisible] = useState(false);

    const dataIndexRef = useRef();
    const [selectedkeyRef, setSelectedkeyRef] = useState();

    /**
     * Check if Smatch is opened in bullhorn
     * @returns {string|null}
     */
    const checkIfInBH = () => {
        try {
            const BhUrl = window.location.href;
            const BhUrlSplit3 = BhUrl.split("EntityID=");
            const BhUrlSplit4 = BhUrlSplit3[1].split("&");
            return BhUrlSplit4[0];
        } catch (e) {
            return null
        }
    }

    const getConnectedUserIfInBH = () => {
        try {
            const BhUrl = window.location.href;
            const BhUrlSplit5 = BhUrl.split("UserID=");
            const BhUrlSplit6 = BhUrlSplit5[1].split("&");
            return BhUrlSplit6[0];

        } catch (e) {
            return null
        }
    }


   /** Get vacancyID from Bullhorn **/
   let vacancyID = checkIfInBH();
   const BHUser = getConnectedUserIfInBH();

    useEffect(async () => {

        if (vacancyID) {
            // we are in BH
                await dispatch(setIdJobOrderExpress(vacancyID));
                if( vacancyID !==  idJobOrderToSend && parseInt(idJobOrderToSend) ) {
                    await window.localStorage.removeItem("state");
                    await window.location.reload();
                    dispatch(setTotalExpress(0));
                }
        }

    }, []);

    const paginate = (page) => {
        setIsLoadingPagination(true);
        const newState = { ...filters };
        newState.skip = (page - 1) * TotalPage;
        setFilters(newState);
    }

    const warningChoices = () => {
        Modal.warning({
            title: 'Alerte',
            content: (<div>     Cette mission est en cours de traitement! <br/>    Veuillez la consulter ultérieurement. </div>),
        });
    };
    const warningFakeOffert = () => {
        Modal.warning({
            title: 'Alerte',
            content: 'Il s\'agit d\'un offre de test! Veuillez saisir un autre.',
        });
    };


    const getResult = async () => {

        try {

            if (vacancyID) {
            const { glob } = await requestData("post", `/matchingExpress`, {
                idJobOrder: parseInt(vacancyID)|| null,
                limit:filters.limit,
                skip: filters.skip,
                BHUser: BHUser,
                filter: {
                    id: parseInt(filters.id, 10)|| null,
                    name: filters.name,
                    email: filters.email,
                    value: parseInt(filters.value, 10)||null ,
                    status: filters.status,
                    countryCode:filters.countryCode,
                    zipCode: filters.zipCode,
                    distance: parseInt(filters.distance, 10)|| null,
                    occupation: filters.occupation,
                    primarySkills: filters.primarySkills? filters.primarySkills.split(" "): null,
                    ExcludeSkill:  filters.ExcludeSkill? filters.ExcludeSkill.split(" "): null,
                    employmentPreference: filters.employmentPreference,
                    dateLastModified: filters.dateLastModified,
                    dateAvailable: filters.dateAvailable,
                    sortValue: filters.sortValue,
                    sortDistance: filters.sortDistance,
                    sortCandidateId : filters.sortCandidateId,


                },
            });

            if(glob){
                const {candidates, total, primaryskills, secondaryskills, AllKeywords, is_Sync, isFake} = glob;

                if(!is_Sync) warningChoices();
                if(isFake) warningFakeOffert();
                dispatch(setMatchingExpress(candidates));
                if(!totalToDisplay)dispatch(setTotalExpress(total));
                setTotalPagination(total);
                dispatch(setPrimarySkillsExpress(primaryskills));
                dispatch(setSecondarySkillsExpress(secondaryskills));
                dispatch(setAllKeyWordsExpress(AllKeywords));
                dispatch(setIsSyncExpress(is_Sync));

                setIsLoadingPagination(false);

                // stop spiner
                setMatchingStarted(false);


                setResultExist(true);

                if(totalToDisplay !== total && totalToDisplay !==0 ){
                    setTotalFiltredCandidates(total);
                }
                // if(totalToDisplay===0){
                //     dispatch(setTotalExpress(total));
                // }
                setTotalPagination(glob.total);


                setRelancement(relancement + 1);

            }}

        } catch (e) {
            console.log("error =>", e);
        }

    }



    useEffect(() => {

            setMatchingStarted(true);
            dispatch(setMatchingExpress([]));
            getResult();
    }, [filters]);





    const searchQuery = (dataIndex, value,confirm) => {
        confirm();
        setIsLoadingPagination(true);
        setFilters((prevState) => ({ ...prevState, [dataIndex]: value, skip:0 }));
        dataIndexRef.current=null;

    };

    const handleTableChange = (pagination, filters, sorter) => {
        if(sorter && sorter.order ){

            setIsLoadingPagination(true);
            if(sorter.field === "value"){
                setFilters((prevState) => ({ ...prevState, "sortValue": sorter.order,"sortDistance": sorter.order, "sortCandidateId": 0, skip:0 }));
            }
            if(sorter.field === "distance") setFilters((prevState) => ({ ...prevState, "sortDistance": sorter.order, "sortCandidateId": 0, "sortValue": 0,skip:0 }));
            if(sorter.field === "id"){
                setFilters((prevState) => ({ ...prevState, "sortCandidateId": sorter.order, "sortValue": 0, "sortDistance": 0, skip:0 }));
            }


        }
        if (pagination && pagination.pageSize){
            setTotalPage(pagination.pageSize);
            setFilters((prevState) => ({ ...prevState, limit: pagination.pageSize }));
        }

    };


    const Search = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, confirm }) => {
            return (
                <div style={{ padding: 8 }}>
                    {dataIndex === "dateLastModified" || dataIndex === "dateAvailable" ? (
                        <DatePicker
                            initialValues={moment("2020-01-01", "YYYY-MM-DD")}
                            format="YYYY-MM-DD"
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(date) => setSelectedKeys(date ? [date] : [])}
                            onPressEnter={() => searchQuery(dataIndex, selectedKeys, confirm)}
                            style={{ marginBottom: 10, display: "flex" }}
                        />
                    ) : dataIndex === "distance" ? (
                        <Input
                            placeholder={`Search by ${dataIndex}`}
                            value={selectedKeys}
                            onChange={(e) => setSelectedKeys(e.target.value)}
                            onPressEnter={() => searchQuery(dataIndex, selectedKeys, confirm)}
                            style={{ marginBottom: 3, display: "flex" }}
                        />
                    ):  (
                        <Input
                            placeholder={`Search by ${dataIndex}`}
                            value={selectedKeys}
                            onChange={(e) => setSelectedKeys(e.target.value)}
                            onPressEnter={() => searchQuery(dataIndex, selectedKeys, confirm)}
                            style={{ marginBottom: 10, display: "flex" }}
                        />
                    )}

                    <div >
                        {dataIndex === "distance" ? (
                            <div style={{color: "red", width: 250 }}>
                                Les candidats pour lesquels la distance est inférieure à la valeur saisie seront affichés.
                            </div>
                        ): null}
                    </div>
                    <div >
                        {dataIndex === "primarySkills" ? (
                            <Input
                                key={"ExcludeSkill"}
                                placeholder={"Exclude skill"}
                                value={selectedkeyRef}
                                onChange={(e) =>{
                                    setSelectedkeyRef( e.target.value) ;
                                    dataIndexRef.current ="ExcludeSkill";
                                } }
                                onPressEnter={() => {
                                    searchQuery("ExcludeSkill", selectedkeyRef, confirm)
                                }}
                                style={{ marginBottom: 10, display: "flex" }}
                            />
                        ): null}
                    </div>

                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>{
                                if(dataIndexRef.current === "ExcludeSkill" ) searchQuery(dataIndexRef.current, selectedkeyRef, confirm)
                                else searchQuery(dataIndex, selectedKeys, confirm)}
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 80 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={ () =>{
                                clearFilters();
                                setIsLoadingPagination(true);
                                /** reset primarySkills and ExcludeSkill**/
                                if(dataIndex==="primarySkills") setFilters((prevState) => ({
                                    ...prevState,
                                    [dataIndex]: initialFilters[dataIndex],
                                    ["ExcludeSkill"]:  initialFilters["ExcludeSkill"] ,
                                    skip: 0,
                                }));
                                else setFilters((prevState) => ({
                                    ...prevState,
                                    [dataIndex]: initialFilters[dataIndex],
                                    skip: 0,
                                }));
                                setTotalFiltredCandidates(0);
                                setTotalPagination(0);
                                setSelectedkeyRef("") ;

                            }

                            }
                            size="small"
                            style={{ width: 80 }}
                        >
                            Reset
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters();
                                setIsLoadingPagination(true);
                                setFilters(initialFilters);
                                setTotalFiltredCandidates(0);
                                setTotalPagination(0);
                                setSelectedkeyRef("") ;

                            }
                            }
                            size="small"
                            style={{ width: 80 }}
                        >
                            Reset All
                        </Button>
                    </Space>
                </div>
            );
        },
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        render: (text) => text,
    });


    const info = (options) => {
        Modal.info({
            title: options.title,
            content: options.content,
            onOk() {},
        });
    };
    const Clean = async (keyWords) => {

        if (keyWords !== "") {
            try {
                const { result,newWord, synonyms } = await requestData("post", `/`, {
                    desc: keyWords,
                    ajouter: true,
                });
                //
                // if (result.length > 0 || newWord.length >0) {
                //
                //     if(synonyms){
                //         let newSynonymList = {...synonymsToDisplay, ...synonyms};
                //         console.log("newSynonymList", newSynonymList)
                //         dispatch(setListSynonyms(newSynonymList));
                //     }
                //
                //     setSpinnerMotCle(false);
                //     setClicked(false);
                //
                //     for (let i = 0; i < result.length; i++) {
                //         //nbrSelection++;
                //         const val = { mots: result[i], checked: false };
                //         const found = keywordsToDisplay.find(
                //             (element) => element.mots === result[i]
                //         );
                //         if (found === undefined){
                //             keywordsToDisplay.push(val);
                //             dispatch(setListKeywords(keywordsToDisplay));
                //         }// dispatch(setListKeywords(oldArray => [...oldArray, val])); //setFinalWords(oldArray => [...oldArray, val]);//setFinalWords([...finalWords, val]); //finalWords.push(val);
                //     }
                //
                //     keywordsToDisplay.sort(function (a, b) {
                //         const nameA = a.mots.toUpperCase(); // ignore upper and lowercase
                //         const nameB = b.mots.toUpperCase(); // ignore upper and lowercase
                //         if (nameA < nameB) {
                //             return -1;
                //         }
                //         if (nameA > nameB) {
                //             return 1;
                //         }
                //         // names must be equal
                //         return 0;
                //     });
                //
                //     setNewWord(newWord);
                //
                //     if (newWord.length !== 0) {
                //         const options = {
                //             title: "La liste des mots non pris en compte :",
                //             content: (
                //                 <diV>
                //                     {newWord.map((w, index) => {
                //                         return (
                //                             <Button
                //                                 danger
                //                                 key={index}
                //                                 style={{ margin: "5px" }}
                //                                 onClick={() => {}}
                //                             >
                //                                 {w}
                //                             </Button>
                //                         );
                //                     })}
                //                     <p>
                //                         ces mots clés seront pris en compte lors de la prochaine
                //                         mise à jour.
                //                     </p>
                //                 </diV>
                //             ),
                //         };
                //
                //         info(options);
                //     }
                // }
            } catch (e) {
                console.log("error =>", e);
            }
        }
    };
    const onFinish2 = async (values) => {
        Clean(values.keyWords);
    };



    const columns = [
        {
            title: "CandidateID",
            dataIndex: "id",
            key: "id",
            sorter: true,
            ...Search("id"),
            fixed: "left",
            width: "10%",
            //sorter: (a, b) => parseInt(a.candidate_id,10)  - parseInt(b.candidate_id,10),
            render: (_, entity) => {
                return (
                    <div>
                        <a
                            href={
                                "https://cls22.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=" +
                                entity["candidate"].id
                            }
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            { entity["candidate"].id}
                        </a>
                    </div>
                );
            },
        },
        {
            title: "Candidate name",
            dataIndex: "name",
            key: "name",
            ...Search("name"),
            width: "10%",
            render: (_, entity) => {
                return (
                    <div>{entity["candidate"].name}</div>
                );
            },
        },
        {
            title: "Contact",
            dataIndex: "email",
            key: "email",
            ...Search("email"),
            width: "20%",
            render: (text, record) => {
                return (
                    <div>
                        <div>{record["candidate"].email && "Email: " + record["candidate"].email}</div>
                        <div>
                            {record["candidate"].phone &&
                            record["candidate"].email !== "None" &&
                            "phone: " + record["candidate"].phone}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Score",
            dataIndex: "value",
            key: "value",
            sorter: true,
            ...Search("value"),
            width: "8%",
            render: (value) => {
                const newValue = value.toFixed(2);
                return newValue;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            ...Search("status"),
            width: "8%",
            render: (_, entity) => {
                return (
                    <div>{entity["candidate"].status}</div>
                );
            },
        },
        {
            title: "Country code",
            dataIndex: "countryCode",
            key: "countryCode",
            ...Search("countryCode"),
            width: "8%",
            render: (_, entity) => {
                return (
                    <div>{entity["candidate"].address.countryCode}</div>
                );
            },
        },
        {
            title: "Zip code",
            dataIndex: "zipCode",
            key: "zipCode",
            ...Search("zipCode"),
            width: "8%",
            render: (_, entity) => {
                return (
                    <div>{entity["candidate"].address.zip}</div>
                );
            },
        },
        {
            title: "Distance",
            dataIndex: "distance",
            key: "distance",
            ...Search("distance"),
            sorter: true,
            // sorter: (a, b) =>
            //     parseInt(parseInt(a.distance) *100) -
            //     parseInt(parseInt(b.distance) * 100),
            width: "8%",
            render: (distance, entity) => {
                if(distance === 50000) return "N/A";
                else  return distance + " km";
            },
        },
        {
            title: "Occupation",
            dataIndex: "occupation",
            sorter: false,
            key: "occupation",
            ...Search("occupation"),
            width: "15%",
            render: (_, entity) => {
                return (
                    <div>{entity["candidate"].occupation}</div>
                );
            },
        },

        {
            title: "Skills",
            dataIndex: "primarySkills",
            key: "primarySkills",
            ...Search("primarySkills"),
            width: "10%",
            render: (_, entity) => {
                //  console.log("entity",entity)
                return (
                    <div>
                        {entity["candidate"].primarySkills.map((tag) => (
                            <Tag color="blue" key={tag}>
                                {tag}
                            </Tag>
                        ))}
                    </div>
                );
            },
        },
        {
            title: "Employment preference",
            dataIndex: "employmentPreference",
            sorter: false,
            key: "employmentPreference",
            ...Search("employmentPreference"),
            width: "11%",
            render: (_, entity) => {
                return (
                    <div>
                        {entity["candidate"].employmentPreference?.map((tag) => (
                            <div key={tag}>
                                {tag} {/* */}
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            title: "Date last modified",
            dataIndex: "dateLastModified",
            key: "dateLastModified",
            // sorter: (a, b) => toDate(a["candidate"].dateLastModified) - toDate(b["candidate"].dateLastModified) ,
            width: "8%",

            ...Search("dateLastModified"),
            render: (_, entity) => {
                // console.log("dateLastModifieddateLastModified",typeof (toDate(entity["fields"][0].dateLastModified)))
                return (
                    <div>{entity["candidate"].dateLastModified}</div>
                );
            },
        },
        {
            title: "Date available",
            dataIndex: "dateAvailable",
            key: "dateAvailable",
            // sorter: (a, b) => toDate(a["candidate"].dateAvailable) - toDate(b["candidate"].dateAvailable),
            width: "9%",

            ...Search("dateAvailable"),
            render: (_, entity) => {
                return (
                    <div>{entity["candidate"].dateAvailable}</div>
                );
            },
        },
    ];
    const reload =  ()=>{
        try{
            localStorage.removeItem("state");
            window.location.reload();
        } catch (e) {
            message.error('Please enable cookies for private navigation.');
        }

    }

    const getForm =()=> {
        setVisible(true);
    }



    return  (
         <div>
           {vacancyID?
        <div>
            <FormModal
                visible={visible}
                setVisible={setVisible}
            />
            <div className={classes.HomePage}>
                <Layout>


                    <Header>
                        <div className={classes.logo}>
                            <input
                                type="image"
                                src="/logo192.png"
                                width="48"
                                height="48"
                                alt=""
                            />
                        </div>
                    </Header>
                    <div className={classes.centre}>

                        <Content style={{ padding: "70px 150px 0px 150px"  }}>

                            <div className={classes.siteLayout}>

                                    <RedoOutlined className={classes.icon}   onClick={reload}/>
                                   <br/>
                                   <br/>

                                    <div>
                                            <div>
                                                <Collapse
                                                    defaultActiveKey={["1"]}
                                                    style={{ textAlign: "center" }}
                                                >
                                                    <Panel header="Liste des mots-clés" key="1" >
                                                        {AllKeywordsToDisplay.map((w, index) => {
                                                                return (
                                                                    <Button
                                                                        key={index}
                                                                       // overlay={menu(w)}
                                                                        disabled={matchingStarted || resultExist}
                                                                        style={{ margin: "10px" }}
                                                                        className="myButton "
                                                                    >
                                                                        {w}
                                                                    </Button>

                                                                );
                                                            return null;
                                                        })}
                                                    </Panel>
                                                </Collapse>
                                                <br />
                                                <Divider />
                                                <Row justify="space-around">
                                                    <Col span={10}>
                                                        <Space direction="vertical">

                                                            <Card
                                                                title="Mots primaires"
                                                                type="inner"

                                                            >
                                                                {primaryskillsToDisplay.map((w, index) => {
                                                                        return (
                                                                            <Button
                                                                                key={index}
                                                                                //overlay={menu(w)}
                                                                                disabled={matchingStarted || resultExist}
                                                                                style={{ margin: "5px" }}
                                                                            >
                                                                                {w}
                                                                            </Button>
                                                                        );
                                                                    return null;
                                                                })}
                                                            </Card>

                                                        </Space>
                                                    </Col>
                                                    <Col span={10}>
                                                        <Space direction="vertical">
                                                            <Card title="Mots secondaires "
                                                                  type="inner">
                                                                {secondaryskillsToDisplay.map((w, index) => {
                                                                        return (
                                                                            <Button
                                                                                key={index}
                                                                               // overlay={menu(w)}
                                                                                disabled={matchingStarted || resultExist}
                                                                                style={{ margin: "5px" }}
                                                                            >
                                                                                {w}
                                                                            </Button>
                                                                        );
                                                                    return null;
                                                                })}
                                                            </Card>

                                                        </Space>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Divider />
                                                <br/>

                                                {candidatesToDisplay.length >0 ? (<Row>
                                                    <div className={classes.chronometre}>
                                                        <div className={classes.tim2}>
                                                            {totalFiltredCandidates ===0 && <div><span>{totalToDisplay}</span> Candidates</div>}
                                                            {totalFiltredCandidates !==0 && <div style={{display: "flex", marginLeft: "25%"}}><span style={{display: "flex", "margin-right": "5px" }}><div style={{background: "yellow"}}>{totalFiltredCandidates}</div>/{totalToDisplay}</span> Candidates</div>}

                                                        </div>
                                                    </div>
                                                </Row>) : null}
                                                <br/>
                                                        <Row>
                                                            <Col >
                                                                <Table
                                                                    columns={columns}
                                                                    dataSource={candidatesToDisplay}
                                                                    scroll={{ x: 2000, y: 600 }}
                                                                    pagination={{
                                                                        onChange: paginate,
                                                                        total: totalPagination? totalPagination:null,
                                                                        showSizeChanger: true
                                                                    }}
                                                                    loading={isLoadingPagination}
                                                                    onChange={handleTableChange}

                                                                />
                                                            </Col>
                                                        </Row>

                                            </div>

                                    </div>
                            </div>
                        </Content>
                        <button onClick={getForm} className={classes.rotate}><div className={classes.button1}>Votre avis </div>  <span className={classes.iconVertical} ><AliwangwangOutlined /></span></button>
                    </div>
                    <Footer />
                </Layout>
            </div>

        </div>
               :null}
         </div>

    );
}
